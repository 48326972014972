import { Flex } from "@chakra-ui/react";

export const FlexIconRow = ({ children, ...props }) => {
  return (
    <Flex
      cursor="pointer"
      w="fit-content"
      flexDir="row"
      gap="8px"
      alignItems="center"
      {...props}
    >
      {children}
    </Flex>
  );
};
