import { createSlice } from "@reduxjs/toolkit";

export interface Dropoff {
  sortColumn?: { accessor: string; direction: 0 | 1 };
}

export const initialState: Dropoff = {};

export const dropoffSlice = createSlice({
  name: "dropoff",
  initialState,
  reducers: {
    update: (state, params) => {
      const f = params.payload as Dropoff;

      state.sortColumn = f.sortColumn;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = dropoffSlice.actions;

export default dropoffSlice.reducer;
