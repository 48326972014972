import { Box, Flex, ModalFooter, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { ReportService } from "../../../service/job/report.service";
import { BlanccoService } from "../../../service/integrations/blancco.service";
import { errorToast, successToast } from "../../../constants/toast.constants";

export const BlanccoSyncModal = ({ isOpen, onClose }) => {
  const reportService = new ReportService();
  const blanccoService = new BlanccoService();

  const weekPrev = () => new Date(new Date().setDate(new Date().getDate() - 7));

  const initialState = {
    from: weekPrev(),
    to: new Date(),
  };

  const [formState, setFormState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [failedQrs, setFailedQrs] = useState([]);

  const toast = useToast();
  const init = async () => {
    setFormState(initialState);
  };

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const { from, to } = formState;

    // validate dates
    if (from && to && new Date(from) > new Date(to)) {
      alert("From date must be greater than To date");
      return;
    }

    setLoading(true);
    await blanccoService
      .syncReports({ gt: from, lte: to })
      .then((errorIds) => {
        toast(successToast("Successfully synced reports"));
        if (!errorIds.length) {
          handleClose();
          return;
        }

        setFailedQrs(errorIds);
        setShowError(true);
      })
      .catch(() => {
        toast(errorToast("Error syncing reports"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState(initialState);
    setLoading(false);
    setShowError(false);
    onClose();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      header="Blancco Sync"
      onClose={handleClose}
      width="500px"
    >
      Please select the date range to sync destruction reports. Try to keep the
      date range shorter than a 3 month period.
      <Box hidden={!showError}>
        Error processing QRs: {failedQrs.join(" - ")}
      </Box>
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Flex
            flexDir="row"
            justifyContent="space-between"
            gap="12px"
            pt="12px"
          >
            <DatepickerInput
              title="From"
              name="from"
              initialDate={weekPrev()}
              onChange={onInputChange}
            />
            <DatepickerInput
              title="To"
              name="to"
              initialDate={new Date()}
              onChange={onInputChange}
            />
          </Flex>

          <ModalFooter>
            <Flex gap="12px" hidden={loading}>
              <ButtonCustom
                secondary
                neutral
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Sync</ButtonCustom>
            </Flex>
            <Box hidden={!loading}>Syncing in progress...</Box>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};
