import { Text, Box, Flex } from "@chakra-ui/react";

export const PageTitle = ({ children }) => {
  return (
    <Flex flexDir="column">
      <Text color="brand.green" fontSize="3xl" fontWeight="bold">
        {children}
      </Text>
      <Box bg="brand.yellow" h="3px" w="40px"></Box>
    </Flex>
  );
};
