import { CompanyModel } from "../../models/company.model";
import { UserProfile } from "../../models/user-profile.model";
import { BaseService } from "../base.service";

export class CompanyService extends BaseService {
  constructor() {
    super();
  }

  async updateCredits(data: {
    id: string;
    credits: number;
    description: string;
  }) {
    const resp = await this.client.put(`/super/company/credits`, data);
    return resp.data;
  }

  async updateCarbonCredits(data: { id: string; credits: number }) {
    const resp = await this.client.put(`/super/company/carbon/credits`, data);
    return resp.data;
  }

  async upsertEmissions(data: {
    id: string;
    companyId: string;
    emission: number;
    description: string | null;
  }) {
    const resp = await this.client.put(`/super/company/emission`, data);
    return resp.data;
  }

  async getEmission(emissionId: string) {
    const resp = await this.client.get(
      `/super/company/emission?emissionId=${emissionId}`
    );

    return resp.data;
  }

  async getEmissions(companyId: string) {
    const resp = await this.client.get(
      `/super/company/emission/list?companyId=${companyId}`
    );

    return resp.data;
  }

  async get(id: string): Promise<CompanyModel> {
    const resp = await this.client.get(`/super/company?id=${id}`);
    return resp.data;
  }

  async getList(
    filter?: {
      isHeadquarters?: boolean;
      parentCompanyId?: string;
    },
    page?: number,
    pageSize?: number,
    sort?: {
      accessor: string;
      direction: 0 | 1;
    }
  ): Promise<any> {
    const resp = await this.client.post(`/super/company/list`, {
      filter,
      page,
      pageSize,
      sort,
    });

    return resp.data;
  }

  async getListForDropdown() {
    const resp = await this.client.get(`/super/company/list/dropdown`);
    return resp.data;
  }

  async setRebateRequired(companyId: string, isRebateRequired: boolean) {
    const resp = await this.client.put(`/super/company/rebate/toggle`, {
      id: companyId,
      required: isRebateRequired,
    });
    return resp.data;
  }

  async exportToCsv(): Promise<any> {
    const resp = await this.client.get(`/super/company/list/export`, {
      responseType: "blob",
    });

    return resp.data;
  }

  async exportCreditHistory(id: string): Promise<any> {
    const resp = await this.client.get(
      `/super/company/credit/export?id=${id}`,
      {
        responseType: "blob",
      }
    );

    return resp.data;
  }

  async globalSearch(term: string) {
    const resp = await this.client.post(`/super/company/search`, {
      filter: { term },
    });

    return resp?.data;
  }

  async updateCompany(data: { name; url; country }) {
    await this.client.put(`/super/company`, data);
  }

  async deactivateCompay(id: string) {
    await this.client.put(`/super/company/deactivate`, { id });
  }
}

export const companyService = new CompanyService();
