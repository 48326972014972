import { Flex, ModalFooter, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { FormBuilderService } from "../../../service/forge/form-builder.service";
import { useSelector } from "react-redux";
import { InventoryCustomForm } from "../../shared/custom-forms/InventoryCustomForm";

export const AddInventoryModal = ({
  isOpen,
  onClose,
  onSubmit,
  jobId,
  assignedAdminId,
}) => {
  const formbuilderService = new FormBuilderService();
  const inventoryService = new InventoryService();
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [formJson, setFormJson] = useState([] as any);

  const { formId } = useSelector((state: any) => state.inventory);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await inventoryService
      .post({ ...formState, jobId })
      .then(() => {
        toast(successToast("Job added successfully."));
        handleClose();
        onSubmit();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setDefaultFormstate = () => {
    setFormState({ ...initialState, assignedAdminId });
  };

  const handleClose = () => {
    setDefaultFormstate();
    onClose();
  };

  const init = async () => {
    const form = await formbuilderService.get(formId);
    setFormJson(form.formJson);
  };

  useEffect(() => {
    setDefaultFormstate();
  }, [assignedAdminId]);

  useEffect(() => {
    init();
  }, [formId]);

  return (
    <Modal
      width="800px"
      isOpen={isOpen}
      header="Add items to job"
      onClose={handleClose}
    >
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <InventoryCustomForm
          initialState={formState}
          json={formJson}
          onInputChange={onInputChange}
        >
          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </InventoryCustomForm>
      </form>
    </Modal>
  );
};

const initialState = {
  status: "QUOTE_PENDING",
  type: "RECYCLING",
  category: "LAPTOP",
  condition: "USED",
  eraseMethod: "NIST_800-88_CLEAR",
  sanitisation: "DESTROY",
  destructionMethod: "SHRED",
  startDate: new Date().toISOString(),
};
