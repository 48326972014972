import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

export const CategoryPickerInput = ({
  id,
  category,
  options,
  onClick,
}: Props) => {
  const [displayCategory, setCategory] = useState("Select Category");

  useEffect(() => {
    if (category) {
      setCategory(options[category] ?? category);
    }
  }, [category]);

  const getKeyByValue = (value) =>
    Object.keys(options).find((key) => options[key] === value);

  return (
    <Menu>
      <MenuButton
        cursor="pointer"
        as={Box}
        h="100%"
        display="flex"
        alignItems="center"
        _hover={{ bgColor: "gray.200" }}
      >
        {displayCategory}
      </MenuButton>
      <MenuList>
        {Object.values(options).map((value: string) => (
          <MenuItem
            onClick={() => {
              setCategory(value);
              onClick(id, getKeyByValue(value));
            }}
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

interface Props {
  id: string;
  category: string;
  options: any; //accepts enum
  onClick: (id: string, value: string) => void;
}
