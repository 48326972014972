import { Flex, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { TextAreaInput } from "../../form/controls/text-area.input";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { FormInput } from "../../form/controls/form.input";
import { CompanyService } from "../../../service/company/company.service";
import { DatepickerInput } from "../../form/controls/date-picker.input";

export const EmissionEditModal = ({ emission, isOpen, onClose, onSubmit }) => {
  const companyService = new CompanyService();
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const init = async () => {
    console.log(emission);
    setFormState(emission);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await companyService
      .upsertEmissions({ ...formState })
      .then(() => {
        toast(successToast("Emission diverted updated successfully."));
        handleClose();
        onSubmit();
      })
      .catch((err) => {
        alert(err);
        toast(errorToast("Error updating."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({});
    onClose();
  };

  useEffect(() => {
    if (emission) {
      init();
    }
  }, [emission]);

  return (
    <Modal
      width="fit-content"
      isOpen={isOpen}
      header="Update emissions diverted"
      onClose={handleClose}
    >
      <Loading loading={loading} fill ignoreDelay />
      <form>
        <Flex flexDir="column" gap="12px">
          <Flex flexDir="row" justifyContent="space-between" gap="12px">
            <DatepickerInput
              required
              name="fromDate"
              title="From"
              initialDate={new Date(emission?.fromDate ?? new Date())}
              onChange={onInputChange}
            />
            <DatepickerInput
              required
              name="toDate"
              title="To"
              initialDate={new Date(emission?.toDate ?? new Date())}
              onChange={onInputChange}
            />
          </Flex>
          <FormInput
            required
            name="emission"
            title="Add emission diverted"
            type="number"
            defaultValue={emission?.emission}
            onChange={onInputChange}
          />
          <TextAreaInput
            name="description"
            title="Description"
            defaultValue={emission?.description}
            onChange={onInputChange}
          />

          <Flex flexDir="row" justifyContent="flex-end">
            <ButtonCustom
              onClick={handleSubmit}
              style={{ width: "fit-content" }}
            >
              Confirm
            </ButtonCustom>
          </Flex>

          <Flex flexDir="row" justifyContent="flex-end" gap="12px" mt="12px">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
