import { useEffect, useState } from "react";
import { Box, Flex, useToast } from "@chakra-ui/react";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { InventoryModel } from "../../../models/inventory.model";
import { ButtonCustom } from "../../../components/form/controls/form.button";
import { PageTitle } from "../../../components/shared/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components/shared/Loading";
import { DropdownInput } from "../../../components/form/controls/dropdown.input";
import { FormInput } from "../../../components/form/controls/form.input";
import { TextAreaInput } from "../../../components/form/controls/text-area.input";
import { InventoryCategory } from "../../../models/enum/inventory/inventory-category.enum";
import { InventoryCondition } from "../../../models/enum/inventory/inventory-condition.enum";
import { InventoryStatus } from "../../../models/enum/inventory/inventory-status.enum";
import { InventoryType } from "../../../models/enum/inventory/inventory-type.enum";
import { successToast } from "../../../constants/toast.constants";

export const InventoryDetail = ({}) => {
  const inventoryService = new InventoryService();

  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState({} as InventoryModel);
  const [formState, setFormState] = useState({} as any);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const init = async () => {
    setLoading(true);
    await inventoryService
      .get(id)
      .then((inventory) => {
        setInventory(inventory);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const obj = { ...inventory, ...formState };
    delete obj.copyCount;

    await inventoryService
      .update(obj)
      .then(() => {
        toast(successToast("Inventory updated."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Flex flexDir="row" justifyContent="space-between" mb={4}>
        <PageTitle>Inventory</PageTitle>
      </Flex>
      <Box bg="white" borderRadius="6px" p="24px" maxWidth="500px">
        <Loading loading={loading} fill ignoreDelay />
        <form onSubmit={handleSubmit}>
          <Flex flexDir="column" gap="12px">
            <Flex flexDir="row" gap="6px">
              <FormInput
                name="make"
                defaultValue={inventory.make}
                title="Make"
                onChange={onInputChange}
              />
              <FormInput
                name="model"
                defaultValue={inventory.model}
                title="Model"
                onChange={onInputChange}
              />
            </Flex>

            <FormInput
              name="serialNumber"
              title="Serial No."
              defaultValue={inventory.serialNumber}
              onChange={onInputChange}
            />

            <Flex flexDir="row" gap="6px">
              <FormInput
                name="platform"
                title="Platform"
                defaultValue={inventory.platform}
                onChange={onInputChange}
              />
              <FormInput
                name="osVersion"
                title="OS Ver."
                defaultValue={inventory.osVersion}
                onChange={onInputChange}
              />
            </Flex>
            <FormInput
              name="brand"
              defaultValue={inventory.brand}
              title="Brand"
              onChange={onInputChange}
            />
            <Flex flexDir="row" gap="6px">
              <FormInput
                name="quotePrice"
                title="Quote price ($)"
                type="number"
                defaultValue={inventory.quotePrice}
                onChange={onInputChange}
              />
              <FormInput
                name="salePrice"
                title="Sale price ($)"
                type="number"
                defaultValue={inventory.salePrice}
                onChange={onInputChange}
              />
            </Flex>
            <Flex flexDir="row" gap="6px">
              <FormInput
                name="processor"
                title="Processor"
                defaultValue={inventory.processor}
                onChange={onInputChange}
              />
              <FormInput
                name="ram"
                title="RAM"
                type="number"
                defaultValue={inventory.ram}
                onChange={onInputChange}
              />
            </Flex>
            <Flex flexDir="row" gap="6px">
              <FormInput
                name="storageSize"
                title="Storage size"
                type="number"
                defaultValue={inventory.storageSize}
                onChange={onInputChange}
              />
              <FormInput
                name="storageType"
                title="Storage type"
                defaultValue={inventory.storageType}
                onChange={onInputChange}
              />
            </Flex>
            <Flex flexDir="row" gap="6px">
              <DropdownInput
                name="type"
                title="Inventory type"
                options={InventoryType}
                defaultValue={inventory.type}
                onChange={onInputChange}
              />
              <DropdownInput
                name="status"
                title="Status"
                options={InventoryStatus}
                defaultValue={inventory.status}
                onChange={onInputChange}
              />
            </Flex>
            <Flex flexDir="row" gap="6px">
              <DropdownInput
                name="category"
                title="Category"
                options={InventoryCategory}
                defaultValue={inventory.category}
                onChange={onInputChange}
              />
              <DropdownInput
                name="condition"
                title="Condition"
                options={InventoryCondition}
                defaultValue={inventory.condition}
                onChange={onInputChange}
              />
            </Flex>
            <TextAreaInput
              title="Additional info"
              name="notes"
              defaultValue={inventory.notes}
              onChange={onInputChange}
            />

            <Flex gap="12px">
              <ButtonCustom
                secondary
                neutral
                onClick={() => navigate(`/app/job/${inventory.jobId}`)}
              >
                Go to job
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </Flex>
        </form>
      </Box>
    </>
  );
};
