import {
  Center,
  Container,
  Flex,
  Image,
  useToast,
  Text,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../styles/Home.module.css";
import { errorToast } from "../../constants/toast.constants";
import { IdentityService } from "../../service/identity/identity.service";
import TokenService from "../../service/identity/token.service";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { update } from "../../store/slice/Account/user-profile.slice";
import { getQueryParam } from "../../util/url.util";
import { update as updateToken } from "../../store/slice/Token/token.slice";
import { updateActiveForm } from "../../store/slice/Account/inventory.slice";

import BasicLayout from "../../components/layout/basic.layout";

export const LoginRedirect: React.FC<any> = (props: any) => {
  const identityService = new IdentityService();
  const magiclinkService = new MagiclinkService();
  const tokenService = new TokenService();

  const { email } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const identityLogin = async (token: string | null) => {
    if (!email) navigate("/app/auth/login");

    if (!token) {
      toast(errorToast("Failed token authentication. Please try again", 7500));
      navigate("/app/auth/login");
    }

    await identityService
      .login(token!)
      .then(async (userProfile) => {
        await tokenService.getAccessToken(token!).then((accessToken) => {
          dispatch(
            update({
              email: email,
              isManager: userProfile.isManager,
              photoUri: userProfile.photoUri,
            })
          );
          dispatch(updateToken({ accessToken }));
          dispatch(updateActiveForm({ formId: userProfile.forms.inventory }));
          navigate("/app/dashboard");
        });
      })
      .catch((_) => {
        navigate("/app/auth/login");
      });
  };

  const finishEmailRedirectLogin = () => {
    const magicCredential = getQueryParam("magic_credential");

    if (magicCredential) {
      magiclinkService.magic?.auth
        .loginWithCredential()
        .then(async (didToken) => await identityLogin(didToken));
    }
  };

  useEffect(() => {
    finishEmailRedirectLogin();
  }, []);

  return (
    <BasicLayout>
      <Flex flexDirection="column" gap="16px">
        <Text alignSelf="center" color="white" fontSize="26px" fontWeight="500">
          Redirecting you to...
        </Text>
        <Box textAlign="center" pt="20px" bg="brand.white" borderRadius="12px">
          <Center>
            <Image src="/logo.svg" alt="Zolo Logo" width="220px" mb={6} />
          </Center>
        </Box>

        <Flex textAlign="center" flexDirection="column" gap="6px">
          <Text fontSize="46px" fontWeight="700" color="brand.yellow">
            E-Waste Management. Re-imagined
          </Text>
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="brand.green"
              color="brand.yellow"
              size="xl"
            />
          </Center>
        </Flex>
      </Flex>
    </BasicLayout>
  );
};
