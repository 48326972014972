import { Image } from "@chakra-ui/react";

export const IconCustom = (props: { fileName; onClick?; style? }) => {
  return (
    <Image
      {...props.style}
      src={`/assets/${props.fileName}.svg`}
      w="24px"
      h="24px"
      cursor={"pointer"}
      onClick={props.onClick}
    />
  );
};
