import { DownloadIcon } from "@chakra-ui/icons";
import { Flex, Box } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { ReportService } from "../../../service/job/report.service";

export const JobDetailHeader = (props: Props) => {
  const reportService = new ReportService();

  const { id, inventoryCount } = props;

  const handleAcknowledgement = async () => {
    const resp = await reportService.downloadAcknowledgementReport(id);

    const blob = new Blob([new Uint8Array(resp.data.data)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `acknowledgement-report-${id}.xlsx`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleItad = async () => {
    const resp = await reportService.downloadItadReport(id);

    const blob = new Blob([new Uint8Array(resp.data.data)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `itad-report-${id}.xlsx`;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Flex flexDirection="row" gap="16px">
      <Box>
        <ButtonCustom
          hidden={!inventoryCount}
          neutral
          style={{ marginRight: "6px" }}
          onClick={handleAcknowledgement}
        >
          Pickup report (.xlsx)
          <DownloadIcon ml="6px" />
        </ButtonCustom>
        <ButtonCustom secondary hidden={!inventoryCount} onClick={handleItad}>
          ITAD report
          <DownloadIcon ml="6px" />
        </ButtonCustom>
      </Box>
    </Flex>
  );
};

interface Props {
  id: string;
  inventoryCount?: number;
}
