import { Checkbox, Flex } from "@chakra-ui/react";
import { useState } from "react";

export const CheckboxInput: React.FC<Props> = (props: Props) => {
  const {
    id,
    title,
    name,
    isChecked,
    defaultChecked,
    onChange,
    children,
    isIndeterminate,
  } = props;
  const handleCheck = (event: any) => {
    const checked = event.target.checked;

    const payload = {
      id,
      isChecked: checked,
      target: {
        name,
        value: checked,
      },
    };

    onChange(payload);
  };

  return (
    <Flex flexDir="row" gap="6px">
      {title && (
        <label style={{ fontWeight: "500" }} htmlFor={id}>
          {title}
        </label>
      )}
      <Checkbox
        isIndeterminate={isIndeterminate}
        isChecked={isChecked}
        defaultChecked={defaultChecked}
        colorScheme="green"
        onChange={handleCheck}
      >
        {children}
      </Checkbox>
    </Flex>
  );
};

interface Props {
  id?: string;
  title?: string;
  name?: string;
  isChecked?: boolean;
  defaultChecked?: boolean;
  isIndeterminate?: boolean;
  onChange: (payload: any) => void;
  children?: any;
}
