export const convertIsoTime = (isoString: string | Date) => {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export function convertUTCtoEnAU(utcString) {
  // Parse the UTC string into a Date object.
  const date = new Date(utcString);

  // Set up the Intl.DateTimeFormat options.
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Australia/Sydney", // This is the time zone for en-AU.
  } as any;

  // Format the date using Intl.DateTimeFormat.
  const formatted = new Intl.DateTimeFormat("en-AU", options).format(date);

  // Extract the date and time components.
  const [datePart, timePart] = formatted.split(", ");

  // Return the combined string.
  return `${timePart.replace(" ", "")} ${datePart}`;
}
