import { Flex, Grid, ModalFooter, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { TextAreaInput } from "../../form/controls/text-area.input";
import { useEffect, useState } from "react";
import { DropoffService } from "../../../service/company/dropoff.service";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { JobStatus } from "../../../models/enum/job-status.enum";
import { CompanyService } from "../../../service/company/company.service";
import { JobService } from "../../../service/job/job.service";
import { UserService } from "../../../service/user/user.service";
import { FormInput } from "../../form/controls/form.input";
import { CheckboxInput } from "../../form/controls/checkbox.input";

export const AddJobModal = ({ isOpen, onClose, onSubmit }) => {
  const companyService = new CompanyService();
  const jobService = new JobService();
  const userService = new UserService();

  const toast = useToast();

  const [formState, setFormState] = useState(initialState as any);
  const [loading, setLoading] = useState(false);
  const [companyDropdown, setCompanyDropdown] = useState([] as any);
  const [userDropdown, setUserDropdown] = useState([] as any);
  const [adminUserDropdown, setAdminUserDropdown] = useState([] as any);

  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onCompanyChange = (event: any) => {
    let { value } = event.target;
    setSelectedCompanyId(value);
    onInputChange(event);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await jobService
      .post(formState)
      .then(() => {
        toast(successToast("Job added successfully."));
        handleClose();
        onSubmit();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState(initialState);
    onClose();
  };

  const init = async () => {
    const dropdown = await companyService.getListForDropdown();
    setCompanyDropdown(dropdown);

    await initAdminUserDropdown();
  };

  const updateUserDropdown = async () => {
    if (selectedCompanyId) {
      const users = await userService.getCompanyUsers(selectedCompanyId);
      let obj = {};

      users.forEach((user) => {
        if (!user.deleted) {
          obj[user.id] = `${user.firstName} ${user.lastName} <${user.email}>`;
        }
      });

      setUserDropdown(obj);
    }
  };

  const initAdminUserDropdown = async () => {
    const users = await userService.getUsers();
    let obj = {};

    users
      .filter((x) => !x.deleted)
      .forEach((user) => {
        obj[user.id] = `${user.email}`;
      });

    setAdminUserDropdown(obj);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    updateUserDropdown();
  }, [selectedCompanyId]);

  return (
    <Modal
      isOpen={isOpen}
      header="Add a job"
      onClose={handleClose}
      width="600px"
    >
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Grid templateColumns="repeat(2, 1fr)" gap={2} rowGap={2}>
            <DatepickerInput
              initialDate={new Date()}
              title="Start date"
              name="startDate"
              onChange={onInputChange}
            />
            <DropdownInput
              name="status"
              title="Status"
              options={JobStatus}
              defaultValue={"PENDING"}
              onChange={onInputChange}
            />
            <DropdownInput
              required
              name="companyId"
              title="Company"
              options={companyDropdown}
              onChange={onCompanyChange}
            />
            <DropdownInput
              name="contactUserId"
              title="Contact user"
              options={userDropdown}
              onChange={onInputChange}
            />
            <DropdownInput
              name="assignedAdminId"
              title="ZOLO Champion"
              options={adminUserDropdown}
              onChange={onInputChange}
            />
            <FormInput
              required
              name="weight"
              title="Weight (kg)"
              type="number"
              defaultValue={0}
              onChange={onInputChange}
            />
          </Grid>
          <TextAreaInput
            title="Additional info"
            name="notes"
            onChange={onInputChange}
          />
          <CheckboxInput
            name="rebateRequired"
            title="Rebate required"
            onChange={onInputChange}
          />
          <CheckboxInput
            name="rebateReady"
            title="Rebate ready"
            onChange={onInputChange}
          />
          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};

const initialState = {
  status: "PENDING",
  startDate: new Date().toISOString(),
};
