const env =
  process.env.REACT_APP_ENV || process.env.ENVIRONMENT || process.env.NODE_ENV;
const isProd =
  env && (env.toLowerCase() === "prod" || env.toLowerCase() === "production");

let apiUrl = "http://localhost:9000";
let instance = "local";
let magiclinkKey = "pk_live_F66938BEE2B16282";

if (isProd) {
  apiUrl = "https://api.zolo.com.au";
  instance = "prod";
  magiclinkKey = "pk_live_9F5ACE3B0807ACF3";
}

const config = {
  apiUrl,
  instance,
  magiclinkKey,
};

export default config;
