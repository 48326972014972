import { useEffect, useState } from "react";
import { Badge, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

export const StatusPickerInput = ({ id, status, options, onClick }: Props) => {
  const [displayStatus, setStatus] = useState("Select Status");
  const statusColors = {
    processed: "green",
    delayed: "red",
    processing: "orange",
    Pending: "yellow",
    Collection: "orange",
    Sorting: "orange",
    Destruction: "red",
    Erasure: "red",
    Complete: "green",
    Sold: "green",
    "Quote Pending": "yellow",
    "Waiting Collection": "orange",
    "Select Status": "none",
    "In Progress": "orange",
    Collected: "orange",
    // Add more status-color mappings here
  };

  useEffect(() => {
    if (status) {
      setStatus(options[status] ?? status);
    }
  }, [status]);

  const getKeyByValue = (value) =>
    Object.keys(options).find((key) => options[key] === value);

  return (
    <Menu>
      <MenuButton
        cursor="pointer"
        as={Badge}
        colorScheme={statusColors[displayStatus]}
      >
        {displayStatus}
      </MenuButton>
      <MenuList>
        {Object.values(options).map((value: string) => (
          <MenuItem
            onClick={() => {
              setStatus(value);
              onClick(id, getKeyByValue(value));
            }}
            key={value}
            value={value}
          >
            {value}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

interface Props {
  id: string;
  status: string;
  options: any; //accepts enum
  onClick: (id: string, value: string) => void;
}
