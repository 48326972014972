import { createSlice } from "@reduxjs/toolkit";

export interface Company {
  name: string;
  credits: number;
  photoUri?: string;
  sortColumn?: { accessor: string; direction: 0 | 1 };
  pageSize?: number;
}

export const initialState: Company = {
  name: "",
  credits: 0,
  sortColumn: { accessor: "name", direction: 0 },
  pageSize: 30,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    update: (state, params) => {
      const f = params.payload as Company;

      state.name = f.name;
      state.credits = f.credits;
      state.photoUri = f.photoUri;

      state.sortColumn = f.sortColumn;
    },
    updatePageSize: (state, params) => {
      const f = params.payload;
      state.pageSize = f.pageSize;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update, updatePageSize } = companySlice.actions;

export default companySlice.reducer;
