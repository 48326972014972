export const SendIcon = ({ fill = "#A1A1AA" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2399 1.27495L1.36486 12.1724C1.16427 12.2795 0.998698 12.4419 0.887903 12.6405C0.777108 12.839 0.725764 13.0652 0.739995 13.2921C0.754226 13.519 0.833432 13.7371 0.968164 13.9202C1.1029 14.1033 1.28747 14.2438 1.49986 14.3249L6.93736 16.3424L7.26735 20.9549C7.30067 21.39 7.46318 21.8052 7.73405 22.1473C8.00491 22.4893 8.37178 22.7427 8.78761 22.8749C9.20345 23.007 9.64928 23.012 10.0679 22.889C10.4866 22.7661 10.859 22.5209 11.1374 22.1849L13.8224 18.8999L19.6574 21.0674C19.8274 21.1303 20.0097 21.1532 20.19 21.1345C20.3704 21.1158 20.544 21.056 20.6976 20.9596C20.8512 20.8633 20.9806 20.733 21.0759 20.5787C21.1713 20.4245 21.2299 20.2504 21.2474 20.0699L22.9949 2.42995C23.0167 2.21449 22.979 1.99718 22.886 1.80161C22.793 1.60605 22.6482 1.4397 22.4673 1.32065C22.2864 1.2016 22.0764 1.1344 21.86 1.12634C21.6436 1.11828 21.4291 1.16968 21.2399 1.27495ZM19.0424 7.27495L12.4574 16.7099C12.1922 17.0584 11.8025 17.2907 11.3698 17.3582C10.9372 17.4257 10.4952 17.3232 10.1365 17.0721C9.77777 16.821 9.53014 16.4408 9.44548 16.0112C9.36083 15.5816 9.44571 15.1359 9.68235 14.7674C9.82485 14.5724 17.8424 6.60745 17.9999 6.43495C18.1152 6.29571 18.2812 6.208 18.4612 6.19112C18.6412 6.17425 18.8206 6.22958 18.9599 6.34495C19.0991 6.46032 19.1868 6.62627 19.2037 6.80631C19.2206 6.98634 19.1652 7.16571 19.0499 7.30495L19.0424 7.27495Z"
        fill={fill}
      />
    </svg>
  );
};
