import { useState } from "react";
import DatePicker from "react-datepicker";
import { Box, Flex, Text } from "@chakra-ui/react";
import styles from "../../../styles/Home.module.css";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

export const DatepickerInput = ({
  name,
  title,
  initialDate,
  required = false,
  onChange,
}: Props) => {
  const [date, setDate] = useState(initialDate);

  return (
    <Box w="100%">
      <Text fontWeight={600} mb="8px" fontSize={"16px"}>
        {title}
        {required ? (
          <Text
            as={"span"}
            fontWeight="700"
            fontSize="16px"
            color="#792551"
            ml="3px"
          >
            *
          </Text>
        ) : (
          <></>
        )}
      </Text>{" "}
      <Flex
        flexDir="row"
        gap="4px"
        border="1px solid #E2E8F0"
        borderRadius="0.375rem"
        py="6px"
        pl="4px"
        w="100%"
      >
        <CalendarMonthOutlinedIcon style={{ color: "lightGray" }} />
        <DatePicker
          wrapperClassName={styles.datepicker}
          dateFormat="dd/MM/yyyy"
          selected={date}
          onChange={(date) => {
            setDate(date);
            onChange({ target: { name, value: date } });
          }}
        />
      </Flex>
    </Box>
  );
};

interface Props {
  name: string;
  title?: string;
  initialDate?: Date | string;
  required?: boolean;
  onChange: ({ target: { name, value } }) => void;
}
