import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { FormInput } from "../../form/controls/form.input";
import { CustomFieldModel } from "../../../models/form-builder/custom-field.model";
import { CustomFieldType } from "../../../models/form-builder/custom-field-type.enum";
import { CheckboxInput } from "../../form/controls/checkbox.input";
import { InventoryType } from "../../../models/enum/inventory/inventory-type.enum";
import { InventoryCategory } from "../../../models/enum/inventory/inventory-category.enum";
import { InventoryCondition } from "../../../models/enum/inventory/inventory-condition.enum";
import { InventoryDestructionMethod } from "../../../models/enum/inventory/inventory-destruction-method.enum";
import { InventoryEraseMethod } from "../../../models/enum/inventory/inventory-erase.enum";
import { InventorySanitisationMethod } from "../../../models/enum/inventory/inventory-sanitisation-method.enum";
import { InventoryStatus } from "../../../models/enum/inventory/inventory-status.enum";
import { DeleteForeverOutlined } from "@mui/icons-material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

export const AddFieldModal = ({
  isOpen,
  onClose,
  onSubmit,
  field = null,
  systemField = false,
}) => {
  const [formState, setFormState] = useState(initialState);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    onSubmit(formState);
  };

  const handleClose = () => {
    setFormState(initialState);
    onClose();
  };

  const handleOptionsChange = (values) => {
    setFormState({
      ...formState,
      options: values,
    });
  };

  useEffect(() => {
    if (field) {
      setFormState(field);
    }
  }, [field?.label]);

  return (
    <Modal
      isOpen={isOpen}
      header={`${field ? "Edit" : "Add"} a custom field`}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Alert hidden={!systemField} status="warning">
            <AlertIcon />
            This is a system field. Limited actions available
          </Alert>
          <FormInput
            required
            disabled={systemField}
            name="label"
            title="Label"
            onChange={onInputChange}
            defaultValue={field?.label}
          />
          <DropdownInput
            required
            uppercase
            disabled={systemField || field?.type}
            name="type"
            title="Field type"
            options={CustomFieldType}
            onChange={onInputChange}
            defaultValue={field?.type}
          />

          <CheckboxInput
            name="required"
            title="Is mandatory?"
            defaultChecked={field?.required}
            onChange={onInputChange}
          />

          {formState.type === CustomFieldType.DROPDOWN && (
            <OptionsSection
              values={field?.options}
              onChange={(values) => handleOptionsChange(values)}
            />
          )}

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};

const OptionsSection = ({ onChange, values = null }) => {
  const [defaultOptionsKeys, setDefaultOptionsKeys] = useState(null);

  const mappings = {
    Type: InventoryType,
    Category: InventoryCategory,
    Condition: InventoryCondition,
    "Destruction method": InventoryDestructionMethod,
    "Erasure method": InventoryEraseMethod,
    Sanitisation: InventorySanitisationMethod,
    Status: InventoryStatus,
  };

  const options = Object.keys(mappings);

  const handleDeleteRow = (value: string) => {
    const values = defaultOptionsKeys.filter((x: any) => x !== value);
    setDefaultOptionsKeys(JSON.parse(JSON.stringify(values)));

    onChange(values);
  };

  const handleAddRow = (value: string) => {
    const values = [...(defaultOptionsKeys ?? []), value];
    setDefaultOptionsKeys(JSON.parse(JSON.stringify(values)));

    onChange(values);
  };

  const handleDropdownChange = (e) => {
    const selectedIndex = e.target.value;
    const selectedOption = options[selectedIndex];
    const selectedEnum = mappings[selectedOption];

    const values = Object.values(selectedEnum);
    setDefaultOptionsKeys(values);
    onChange(values);
  };

  useEffect(() => {
    if (values) {
      setDefaultOptionsKeys(values);
    }
  }, [values?.length]);

  return (
    <Flex p="12px" border="1px dashed gray" flexDir="column" gap="16px">
      <DropdownInput
        uppercase
        name="values"
        title="Select default (optional)"
        options={options}
        onChange={handleDropdownChange}
      />
      <Flex flexDir="row" justifyContent="space-between">
        <label style={{ fontWeight: "500" }}>Dropdown options</label>
        <AddOptionIcon onAdd={handleAddRow} />
      </Flex>

      {defaultOptionsKeys && (
        <Flex flexDir="column" gap="8px">
          {defaultOptionsKeys.map((x, i) => (
            <Flex
              p="4px"
              bg={+i % 2 === 0 ? "lightGray" : undefined}
              key={i}
              flexDir="row"
              gap="8px"
              justifyContent="space-between"
            >
              <Box key={x}>{x}</Box>
              <DeleteForeverOutlined onClick={() => handleDeleteRow(x)} />
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const AddOptionIcon = ({ onAdd }) => {
  const [label, setLabel] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAdd = () => {
    onAdd(label);
    onClose();
    setLabel("");
  };

  return (
    <>
      <AddCircleOutlineOutlinedIcon onClick={onOpen} />
      <Modal header="Add options" isOpen={isOpen} onClose={onClose}>
        <Flex flexDir="column" gap="26px">
          <FormInput
            name="option"
            title="Option"
            onChange={(e) => setLabel(e.target.value)}
          />
          <Flex flexDir="row" justifyContent="flex-end">
            <ButtonCustom style={{ width: "fit-content" }} onClick={handleAdd}>
              Add
            </ButtonCustom>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

const initialState = { required: false } as CustomFieldModel;
