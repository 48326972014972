import { Flex } from "@chakra-ui/react";
import { AccountUserCard } from "../../components/app/account/user/AccountUserCard";
import { CustomProfileCard } from "../../components/app/account/profile/AccountProfileCard";
import { AccountFinanceCard } from "../../components/app/account/profile/AccountFinanceCard";

export const Account = ({}) => {
  return (
    <Flex gap="24px" pt="30px" px="15%">
      <Flex flex={1} direction="column" gap="24px">
        <CustomProfileCard />
        <AccountFinanceCard />
        <AccountUserCard />
      </Flex>
    </Flex>
  );
};
