import { Box } from "@chakra-ui/react";
import { useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { FormTitle } from "../form.title";

export const CountryDropdownInput = ({
  name = "country",
  title = "Country",
  defaultValue = undefined,
  onChange,
}) => {
  const [country, setCountry] = useState(defaultValue);

  const handleChange = (val: any) => {
    setCountry(val);
    const payload = { target: { name, value: val } };
    onChange(payload);
  };

  return (
    <>
      <FormTitle title={title} required />
      <Box
        as={CountryDropdown}
        border="1px solid #ccc"
        p="8px 12px !important"
        borderRadius="0.375rem"
        value={country}
        whitelist={["AU", "PH", "CN"]}
        onChange={handleChange}
      />
    </>
  );
};
