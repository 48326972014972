import React from "react";
import styles from "../../styles/Home.module.css";
import { Box, Text, Flex } from "@chakra-ui/react";
import { Sidebar } from "../app/Sidebar";
import Header from "../app/header";

const AppLayout: React.FC<any> = (props: any) => {
  const { title, metaAttrs, children } = props;

  return (
    <Box bg="brand.white">
      <Sidebar />
      <Header />
      <Box pl={{ base: 0, lg: "240px" }}>
        <main id="main" className={styles.main}>
          {children}
          {/* <SidebarMobile /> */}
        </main>
      </Box>
    </Box>
  );
};

export default AppLayout;
