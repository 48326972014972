import { BaseService } from "../base.service";

export class ReportService extends BaseService {
  constructor() {
    super();
  }

  async downloadAcknowledgementReport(jobId: string) {
    const resp = await this.client.get(
      `/super/report/job/acknowledgement?jobId=${jobId}`
    );

    return resp.data;
  }

  async downloadItadReport(jobId: string) {
    const resp = await this.client.get(`/super/report/job/itad?jobId=${jobId}`);

    return resp.data;
  }

  async downloadImpactReport(from: Date, to: Date) {
    const resp = await this.client.get(
      `/super/report/admin/impact?from=${from}&to=${to}`
    );

    return resp.data;
  }
}
