import { Flex, ModalFooter, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { Loading } from "../../shared/Loading";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { FormInput } from "../../form/controls/form.input";
import { FormBuilderService } from "../../../service/forge/form-builder.service";
import { CustomFormModel } from "../../../models/form-builder/custom-form.model";
import { EntityType } from "../../../models/enum/entity-type.enum";
import { errorToast } from "../../../constants/toast.constants";

export const AddFormModal = ({ isOpen, onClose, onSubmit }) => {
  const formbuilderService = new FormBuilderService();

  const toast = useToast();

  const [formState, setFormState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await formbuilderService
      .post(formState)
      .then((id) => {
        onSubmit(id);
      })
      .catch((err) => {
        toast(
          errorToast(
            "Failed to add form. Ensure the name is unique and try again."
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState(initialState);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} header="Add a custom form" onClose={handleClose}>
      <Loading loading={loading} fill />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <FormInput
            required
            name="name"
            title="Form name"
            onChange={onInputChange}
          />
          <DropdownInput
            required
            uppercase
            name="entityType"
            title="Entity type"
            options={{ INVENTORY: EntityType.INVENTORY }}
            defaultValue={initialState.entityType}
            onChange={onInputChange}
          />

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};

const initialState = {
  entityType: "INVENTORY",
} as CustomFormModel;
