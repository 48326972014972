import { Flex, ModalFooter } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { ReportService } from "../../../service/job/report.service";
import { DropoffService } from "../../../service/company/dropoff.service";
import { downloadCsvToMachine } from "../../../util/file.util";

export const SummaryReportModal = ({ isOpen, onClose }) => {
  const reportService = new ReportService();
  const dropoffService = new DropoffService();

  const fortnightPrev = () =>
    new Date(new Date().setDate(new Date().getDate() - 14));

  const initialState = {
    from: fortnightPrev(),
    to: new Date(),
  };

  const [formState, setFormState] = useState(initialState);

  const init = async () => {
    setFormState(initialState);
  };

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const { from, to } = formState;

    // validate dates
    if (from && to && new Date(from) > new Date(to)) {
      alert("From date must be greater than To date");
      return;
    }

    await reportService.downloadImpactReport(from, to).then((resp) => {
      downloadCsvToMachine(resp.data, "impact-report.csv");
      handleClose();
    });
  };

  const handleClose = () => {
    setFormState(initialState);
    onClose();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      header="Generate Impact Report"
      onClose={handleClose}
      width="500px"
    >
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Flex
            flexDir="row"
            justifyContent="space-between"
            gap="12px"
            pt="12px"
          >
            <DatepickerInput
              title="From"
              name="from"
              initialDate={fortnightPrev()}
              onChange={onInputChange}
            />
            <DatepickerInput
              title="To"
              name="to"
              initialDate={new Date()}
              onChange={onInputChange}
            />
          </Flex>

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom
                secondary
                neutral
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};
