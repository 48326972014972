import { InputGroup, Input, Flex, Text, propNames } from "@chakra-ui/react";
import { useState } from "react";
import { TooltipCustom } from "./form.tooltip";
import {
  validatePhoneNumber,
  validateEmail,
  validateUrl,
} from "../../../util/form.util";

export const FormInput: React.FC<Props> = (props: Props) => {
  const { type } = props;
  const [isInvalid, setIsInvalid] = useState(false);

  const shouldValidate =
    type === "tel" || type === "email" || type === "url" || type === "number";

  const onChangeValidate = (e: any) => {
    if (shouldValidate) {
      const val = e.target.value;

      let isValid = true;
      if (type === "tel") {
        isValid = !!validatePhoneNumber(val);
      } else if (type === "email") {
        isValid = !!validateEmail(val);
      } else if (type === "url") {
        isValid = !!validateUrl(val);
      } else if (type === "number") {
        isValid = +val >= 0;
      }

      if (!isValid) {
        setIsInvalid(true);
        if (!!props.onChange) props.onChange(e);
        return;
      }

      setIsInvalid(false);
    }

    if (!!props.onChange) props.onChange(e);
  };

  return (
    <Flex flexDirection={"column"}>
      <Flex flexDir="row" fontWeight={600} mb="8px" fontSize={"14px"} pt={0}>
        {props.title ?? <></>}
        {props.required ? (
          <Text
            as={"span"}
            fontWeight="700"
            fontSize="14px"
            color="brand.green"
            ml="3px"
          >
            *
          </Text>
        ) : (
          <></>
        )}
        {props.tooltipText ? (
          <TooltipCustom style={{ ml: "6px" }} label={props.tooltipText} />
        ) : (
          <></>
        )}
      </Flex>
      <InputGroup>
        {props.leftAddon}
        <Input
          onFocusCapture={props.onFocusCapture}
          autoFocus={props.autoFocus}
          p={"8px 12px"}
          readOnly={props.readonly}
          disabled={props.disabled}
          required={props.required}
          defaultValue={props.defaultValue}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          // Add custom url validation
          type={props.type === "url" ? undefined : props.type ?? "text"}
          isInvalid={isInvalid}
          onInput={onChangeValidate}
          maxLength={props.maxLength ?? props.type === "url" ? 2048 : 80}
        />
        {props.rightAddon}
      </InputGroup>
      {isInvalid ? (
        <Text pt="6px" color="#B91C1C" fontSize="14px">
          Invalid input
        </Text>
      ) : (
        <></>
      )}
    </Flex>
  );
};

class Props {
  name: string;
  title?: string;
  defaultValue?: string | number;
  value?: string | number;
  type?: undefined | "number" | "tel" | "email" | "url";
  placeholder?: string;
  leftAddon?: any;
  rightAddon?: any;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  tooltipText?: string;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onFocus?: any;
  onFocusCapture?: any;
  onBlur?: any;
  ref?: any;
  autoFocus?: boolean;
}
