export const onQrDownload = (qrCodeId?: string) => {
  const parentElement = document.getElementById(qrCodeId ?? "QRCode");
  const svg = parentElement?.getElementsByTagName("svg")[0];
  const svgData = new XMLSerializer().serializeToString(svg!);

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.onload = () => {
    // Add additional height to the canvas for the text
    canvas.width = img.width;
    canvas.height = img.height + 50; // 50px should be enough for one line of text; adjust as necessary

    ctx!.drawImage(img, 0, 0);

    // Draw the text onto the canvas
    ctx!.font = "40px Arial";
    const text = `# ${parentElement?.children[1].innerHTML}`;
    ctx!.fillText(text, 10, canvas.height - 20);

    const pngFile = canvas.toDataURL("image/png");

    const downloadLink = document.createElement("a");
    downloadLink.download = qrCodeId ?? "QRCode";
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

export const onQrPrint = (qrCodeId?: string) => {
  const parentElement = document.getElementById(qrCodeId ?? "QRCode");
  const svg = parentElement?.getElementsByTagName("svg")[0];
  const svgData = new XMLSerializer().serializeToString(svg!);

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height + 50; // Adjust as necessary

    ctx!.drawImage(img, 0, 0);

    ctx!.font = "40px Arial";
    const text = `# ${parentElement?.children[1].innerHTML}`;
    ctx!.fillText(text, 10, canvas.height - 20); // Adjust as necessary

    const imageDataURL = canvas.toDataURL("image/png");

    const printWindow = window.open();
    printWindow!.document.write(
      "<html><head><title>Print QR Code</title></head><body>"
    );
    const imgTag = '<img id="printImage" src="' + imageDataURL + '"/>';
    printWindow!.document.write(imgTag);
    printWindow!.document.write("</body></html>");
    printWindow!.document.close();

    // Wait until the new window has loaded before calling print
    printWindow!.addEventListener("load", function () {
      printWindow!.print();
    });
  };

  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

export const onBulkQrPrint = async (bulkQrCodeId: string = "BulkQRCode") => {
  const parentElement = document.getElementById(bulkQrCodeId);
  const qrElements = parentElement?.getElementsByClassName("QRCode");
  if (!qrElements) return;

  const canvases = [];
  const svgWidth = 90; // Width of QR code in pixels (to fit into 44mm box)
  const svgHeight = 86; // Height of QR code in pixels (to fit into 20mm box)

  for (let i = 0; i < qrElements.length; i++) {
    const svg = qrElements[i].getElementsByTagName("svg")[0];
    const svgData = new XMLSerializer().serializeToString(svg!);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

    // Wait for image to load
    await new Promise((resolve) => {
      img.onload = () => {
        canvas.width = svgWidth;
        canvas.height = svgHeight + 50; // Adjust as necessary

        ctx!.drawImage(img, 0, 0, svgWidth, svgHeight);

        ctx!.strokeStyle = "red";
        ctx!.lineWidth = 1; // Adjust the width of the border as necessary
        ctx!.strokeRect(0, 0, svgWidth, svgHeight);

        ctx!.font = "20px Arial";
        const text = `# ${qrElements[i].children[1].innerHTML}`;
        ctx!.fillText(text, 10, canvas.height - 20); // Adjust as necessary

        resolve("");
      };
    });

    canvases.push(canvas);
  }

  // Now let's draw all these canvases into one
  const finalCanvas = document.createElement("canvas");
  const ctx = finalCanvas.getContext("2d");
  const columns = 4; // You mentioned 4 columns
  const rows = 12; // You mentioned 12 rows

  const xSpacing = 25 * 3.7795275591; // 2.5mm horizontal spacing in pixels
  const ySpacing = 0; // No vertical spacing

  const leftRightMargin = 8 * 3.7795275591; // 4mm left and right margin in pixels
  const topBottomMargin = 15 * 3.7795275591; // 10mm top and bottom margin in pixels

  finalCanvas.width = 210 * 3.7795275591; // Width of A4 paper in pixels
  finalCanvas.height = 297 * 3.7795275591; // Height of A4 paper in pixels

  for (let i = 0; i < canvases.length; i++) {
    const x = leftRightMargin + (i % columns) * (svgWidth + xSpacing);
    const y =
      topBottomMargin + Math.floor(i / columns) * (svgHeight + ySpacing);
    ctx!.drawImage(canvases[i], x, y, svgWidth, svgHeight);
  }

  const imageDataURL = finalCanvas.toDataURL("image/png");

  const printWindow = window.open();
  printWindow!.document.write(
    `<html><head><title>Print QR Code</title><style>
        @media print {
          body {
            margin: 0; /* No margin in the print preview, as the margins are already handled in the canvas */
          }
          #printImage {
            height: 100%; /* make sure the image takes up the entire page */
            width: 100%;
          }
        }
      </style></head><body>`
  );
  const imgTag = '<img id="printImage" src="' + imageDataURL + '"/>';
  printWindow!.document.write(imgTag);
  printWindow!.document.write("</body></html>");
  printWindow!.document.close();

  // Wait until the new window has loaded before calling print
  printWindow!.addEventListener("load", function () {
    printWindow!.print();
  });
};

// export const onBulkQrPrint = async (bulkQrCodeId: string = "BulkQRCode") => {
//   const parentElement = document.getElementById(bulkQrCodeId);
//   const qrElements = parentElement?.getElementsByClassName("QRCode");
//   if (!qrElements) return;

//   const canvases = [];
//   const svgWidth = 150; // set the width of your SVGs here
//   const svgHeight = 155; // set the height of your SVGs here

//   for (let i = 0; i < qrElements.length; i++) {
//     const svg = qrElements[i].getElementsByTagName("svg")[0];
//     const svgData = new XMLSerializer().serializeToString(svg!);

//     const canvas = document.createElement("canvas");
//     const ctx = canvas.getContext("2d");

//     const img = new Image();
//     img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

//     // Wait for image to load
//     await new Promise((resolve) => {
//       img.onload = () => {
//         canvas.width = svgWidth;
//         canvas.height = svgHeight + 50; // Adjust as necessary

//         ctx!.drawImage(img, 0, 0, svgWidth, svgHeight);

//         ctx!.font = "40px Arial";
//         const text = `# ${qrElements[i].children[1].innerHTML}`;
//         ctx!.fillText(text, 10, canvas.height - 20); // Adjust as necessary

//         resolve("");
//       };
//     });

//     canvases.push(canvas);
//   }

//   // Now let's draw all these canvases into one
//   const finalCanvas = document.createElement("canvas");
//   const ctx = finalCanvas.getContext("2d");
//   const columns = Math.ceil(Math.sqrt(canvases.length)); // Arranging in square grid, adjust as needed

//   const spacing = 36; // Spacing between each QR code

//   finalCanvas.width = columns * (svgWidth + spacing);
//   finalCanvas.height =
//     Math.ceil(canvases.length / columns) * (svgHeight + spacing + 50);

//   for (let i = 0; i < canvases.length; i++) {
//     const x = (i % columns) * (svgWidth + spacing);
//     const y = Math.floor(i / columns) * (svgHeight + spacing + 50);
//     ctx!.drawImage(canvases[i], x, y);
//   }

//   const imageDataURL = finalCanvas.toDataURL("image/png");

//   const printWindow = window.open();
//   printWindow!.document.write(
//     `<html><head><title>Print QR Code</title><style>
//         @media print {
//           body {
//             margin: 36px; /* adjust as necessary */
//           }
//         }
//       </style></head><body>`
//   );
//   const imgTag = '<img id="printImage" src="' + imageDataURL + '"/>';
//   printWindow!.document.write(imgTag);
//   printWindow!.document.write("</body></html>");
//   printWindow!.document.close();

//   // Wait until the new window has loaded before calling print
//   printWindow!.addEventListener("load", function () {
//     printWindow!.print();
//   });
// };
