import { DropoffModel } from "../../models/dropoff.model";
import { BaseService } from "../base.service";

export class DropoffService extends BaseService {
  constructor() {
    super();
  }

  async post(data: any) {
    return await this.client.post(`/company/dropoff`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async updateStatus(data: any) {
    return await this.client.put(`/super/dropoff/status`, data);
  }

  async getList(filter?: { companyId: string }): Promise<DropoffModel[]> {
    const resp = await this.client.post(`/super/dropoff/list`, filter);
    return resp.data;
  }

  async exportToCsv(filter?: any): Promise<any> {
    const resp = await this.client.post(`/super/dropoff/list/export`, filter, {
      responseType: "blob",
    });
    return resp.data;
  }

  async downloadFile(data: { id: string }): Promise<any> {
    const resp = await this.client.post(`/super/dropoff/media/download`, data);
    return resp.data;
  }
}
