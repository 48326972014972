import { Box, Flex, useToast } from "@chakra-ui/react";
import { DropdownInput } from "../../../components/form/controls/dropdown.input";
import { FormInput } from "../../../components/form/controls/form.input";
import { EntityType } from "../../../models/enum/entity-type.enum";
import { CardCustom } from "../../../components/shared/CardCustom";
import { CheckboxInput } from "../../../components/form/controls/checkbox.input";
import { FormBuilderService } from "../../../service/forge/form-builder.service";
import { useEffect, useState } from "react";
import { CustomFormModel } from "../../../models/form-builder/custom-form.model";
import { ButtonCustom } from "../../form/controls/form.button";
import { errorToast, successToast } from "../../../constants/toast.constants";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useNavigate } from "react-router-dom";
import { updateActiveForm } from "../../../store/slice/Account/inventory.slice";
import { useDispatch } from "react-redux";

export const BasicDetails = ({ form }) => {
  const formbuilderService = new FormBuilderService();
  const [formState, setFormState] = useState({} as CustomFormModel);
  const [checked, setChecked] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleCheck = async () => {
    if (!checked) {
      await handleActivate();
    } else {
      await handleDeactivate();
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await formbuilderService
      .put(formState)
      .then((res) => {
        toast(successToast("Form updated successfully."));
      })
      .catch(() => {
        toast(
          errorToast(
            "There was an error saving these details. Please make sure the name is unique.",
            5000
          )
        );
      });
  };

  const handleActivate = async () => {
    await formbuilderService.activate(form).then((res) => {
      setChecked(true);
      toast(
        successToast(
          "Form activated. All others have been deactivated for this Entity and Form type.",
          5000
        )
      );

      dispatch(updateActiveForm({ formId: form.id }));
    });
  };

  const handleDeactivate = async () => {
    await formbuilderService
      .deactivate(form)
      .then((activeId) => {
        setChecked(false);
        toast(
          successToast(
            "Form deactivated. A different form has been activated for this Entity and Form type.",
            5000
          )
        );

        dispatch(updateActiveForm({ formId: activeId }));
      })
      .catch(() => {
        toast(
          errorToast(
            "Failed to inactivate form. Ensure another form with the same Entity and Form type exists.",
            5000
          )
        );
      });
  };

  const handleDuplicate = async () => {
    await formbuilderService
      .duplicate(form.id)
      .then((id) => {
        navigate(`/app/forge/builder/${id}`);
        toast(successToast("Form duplicated successfully."));
      })
      .catch(() => {
        toast(errorToast("Failed to duplicate form.", 5000));
      });
  };

  const handleDelete = async () => {
    await formbuilderService
      .delete(form.id)
      .then(() => {
        navigate(`/app/forge`);
        toast(successToast("Form deleted successfully."));
      })
      .catch((err) => {
        toast(errorToast(err.response.data.message));
      });
  };

  useEffect(() => {
    setFormState(form);
    setChecked(form.active);
  }, [form.id]);

  return (
    <CardCustom width="70%">
      <Flex mb="12px" flexDir="row" gap="6px" justifyContent="space-between">
        <Box color="brand.green" fontSize="20px" fontWeight="bold">
          Details
        </Box>
        <Flex flexDir="row" gap="6px">
          <ButtonCustom onClick={handleDuplicate} neutral>
            <FileCopyOutlinedIcon />
          </ButtonCustom>
          <ButtonCustom neutral danger onClick={handleDelete}>
            <DeleteForeverOutlinedIcon />
          </ButtonCustom>
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Flex mb="12px" w="100%" flexDir="row" gap="12px">
          <Box w="65%">
            <FormInput
              required
              name="name"
              title="Form name"
              defaultValue={formState.name}
              onChange={onInputChange}
            />
          </Box>

          <DropdownInput
            disabled
            title="Entity type"
            options={{ INVENTORY: EntityType.INVENTORY }}
            defaultValue={formState.entityType}
          />
        </Flex>
        <Flex
          flexDir="row"
          p="6px"
          borderRadius="8px"
          justifyContent="space-between"
          alignItems="center"
        >
          <CheckboxInput
            name="active"
            title="Make this active"
            isChecked={checked}
            onChange={handleCheck}
          />
          <ButtonCustom type="submit">Save changes</ButtonCustom>
        </Flex>
      </form>
    </CardCustom>
  );
};
