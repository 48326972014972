import { DownloadIcon } from "@chakra-ui/icons";
import { Badge, Box, Center, Flex } from "@chakra-ui/react";
import { ButtonCustom } from "../../../components/form/controls/form.button";
import { AddFormModal } from "../../../components/app/forge/add-form.modal";
import { useEffect, useState } from "react";
import { CustomFormModel } from "../../../models/form-builder/custom-form.model";
import { FormBuilderService } from "../../../service/forge/form-builder.service";
import { PageTitle } from "../../../components/shared/PageTitle";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";

export const Forge = () => {
  const formService = new FormBuilderService();

  const [addOpen, setAddOpen] = useState(false);
  const [forms, setForms] = useState([] as CustomFormModel[]);
  const navigate = useNavigate();

  const init = async () => {
    await formService.getList().then((res) => {
      const forms = res.filter((x) => !x.deleted);
      setForms(forms);
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Flex flexDir="row" justifyContent="flex-end" mb={4}>
        <ButtonCustom onClick={() => setAddOpen(true)}>
          Add custom form
        </ButtonCustom>
      </Flex>

      <Flex flexWrap="wrap">
        {forms.map((form) => (
          <CardFlex
            active={form.active}
            onClick={() => navigate(`/app/forge/builder/${form.id}`)}
          >
            <Flex flexDir="column">
              <Box fontSize="14px" fontWeight="bold" color="brand.gray">
                {form.isDefault ? (
                  <Flex flexDir="row" alignSelf="center">
                    <LockIcon />
                    Default
                  </Flex>
                ) : (
                  "Custom "
                )}
              </Box>
              <Box fontSize="20px" fontWeight="bold" color="brand.green">
                {form.name}
              </Box>
            </Flex>
            <Box>
              <Badge
                colorScheme={form.active ? "green" : "gray"}
                w="fit-content"
              >
                {form.active ? "ACTIVE" : "Inactive"}
              </Badge>
              <Box fontWeight="bold">{form.entityType}</Box>
            </Box>
          </CardFlex>
        ))}
      </Flex>

      <AddFormModal
        isOpen={addOpen}
        onClose={() => {
          setAddOpen(false);
        }}
        onSubmit={(id) => navigate(`/app/forge/builder/${id}`)}
      />
    </>
  );
};

const CardFlex = ({ children, onClick, active = false }) => {
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      p={4}
      mb={4}
      border="1px solid"
      borderColor={active ? "green.300" : "gray.200"}
      borderRadius="md"
      w="250px"
      h="300px"
      m={2}
      onClick={onClick}
      _hover={{ cursor: "pointer" }}
    >
      {children}
    </Flex>
  );
};
