import React, { ReactElement } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

interface PopoverCustomProps {
  trigger: ReactElement;
  width?: any;
  showArrow?: boolean;
  placement?: "bottom" | "right" | "left" | "top" | undefined;
  children: ReactElement;
}

const PopoverCustom: React.FC<PopoverCustomProps> = ({
  trigger,
  width,
  showArrow = true,
  placement,
  children,
}) => (
  <Popover placement={placement}>
    <PopoverTrigger>{trigger}</PopoverTrigger>
    <PopoverContent width={width}>
      {showArrow && <PopoverArrow />}
      <PopoverBody>{children}</PopoverBody>
    </PopoverContent>
  </Popover>
);

export default PopoverCustom;
