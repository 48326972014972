import "./App.css";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { Loading } from "./components/shared/Loading";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { ProviderWrapper } from "./components/app-registration/provider-wrapper";
import { AuthenticatedRoute } from "./components/app-registration/authenticated-route";
import { Home } from "./views/Home";
import Login from "./views/auth/Login";
import { LoginRedirect } from "./views/auth/LoginRedirect";
import { Account } from "./views/app/Account";
import { Dashboard } from "./views/app/Dashboard";
import { Dropoff } from "./views/app/Dropoff";
import { Job } from "./views/app/job/Job";
import { Company } from "./views/app/company/Company";
import { CompanyDetail } from "./views/app/company/CompanyDetail";
import { JobDetail } from "./views/app/job/JobDetail";
import { Inventory } from "./views/app/inventory/Inventory";
import { InventoryDetail } from "./views/app/inventory/InventoryDetail";
import { Forge } from "./views/app/forge/Forge";
import { FormBuilder } from "./views/app/forge/FormBuilder";

function App() {
  return (
    <Provider store={store}>
      <ProviderWrapper>
        <PersistGate
          loading={<Loading loading={true} fill />}
          persistor={persistor}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<AuthenticatedRoute />}>
                <Route path="/" element={<Dropoff />} />
              </Route>
              <Route path="*" element={<Navigate to="/app/dropoff" />} />
              <Route path="/app/auth/login" element={<Login />} />

              <Route
                path="/app/auth/login/redirect/:email"
                element={<LoginRedirect />}
              />

              {/* app */}
              <Route path="/app" element={<AuthenticatedRoute />}>
                {/* <Route path="dashboard" element={<Dashboard />} /> */}
                <Route path="forge" element={<Forge />} />
                <Route path="forge/builder/:id" element={<FormBuilder />} />
                <Route path="dropoff" element={<Dropoff />} />
                <Route path="job" element={<Job />} />
                <Route path="job/:id" element={<JobDetail />} />
                <Route path="company" element={<Company />} />
                <Route path="company/:id" element={<CompanyDetail />} />
                <Route path="inventory" element={<Inventory />} />
                <Route path="inventory/:id" element={<InventoryDetail />} />
                <Route path="account" element={<Account />} />
              </Route>

              {/* Authenticated route example */}
              <Route
                path="/example/authenticated"
                element={<AuthenticatedRoute />}
              >
                <Route
                  path="/example/authenticated"
                  element={<>This is authenticated page.</>}
                />
              </Route>

              {/* Unauthenticated redirect */}
              <Route
                path="/unauthorized"
                element={<>Redirected since not authenticated.</>}
              />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </ProviderWrapper>
    </Provider>
  );
}

export default App;
