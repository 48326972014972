import { Magic } from "magic-sdk";
import config from '../../configs';

declare let window: any;

export class BaseMagiclinkService {
  public magic?: Magic;

  constructor() {
    this.magic =
      typeof window !== "undefined"
        ? new Magic(config.magiclinkKey)
        : undefined;
  }
}
