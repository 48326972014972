import {
  Badge,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Image,
  Thead,
  Tr,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CardTitle } from "../../../shared/CardTitle";
import AlertDialog from "../../../shared/AlertDialog";
import { useState } from "react";
import { jobService } from "../../../../service/job/job.service";
import { errorToast, successToast } from "../../../../constants/toast.constants";

export const CompanyDetailJobTable = ({ jobs, onDelete }) => {
  const navigate = useNavigate();
  const toast = useToast();
  
  const [deleteJobModal, setDeleteJobModal] = useState({ id: "", open: false });
  
  const deleteJob = async () => {
    try {
      await jobService.delete(deleteJobModal.id);
      setDeleteJobModal({ id: "", open: false });
      toast(successToast("Job deleted successfully"))
      onDelete();
    } catch (error) {
      toast(errorToast("Error deleting job"))
    }
  }

  const columns = [
    {
      header: "Ref #",
      accessor: "jobNo",
      callback: (jobNo: string) => <Text fontWeight="600">{jobNo}</Text>,
    },
    {
      header: "Company name",
      accessor: "companyName",
      callback: (companyName: string) => <>{companyName}</>,
    },
    { header: "Contact", accessor: "contactEmail" },
    {
      header: "No. items",
      accessor: "totalInventory",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Status",
      accessor: "status",
      callback: (status: string) => (
        <Badge
          colorScheme={
            status === "COMPLETE"
              ? "green"
              : status === "PENDING"
              ? "red"
              : "orange"
          }
        >
          {status}
        </Badge>
      ),
    },
    {
      header: "Total quote",
      accessor: "totalQuotePrice",
      callback: (price: number) => <>{price ? <Box>${price}</Box> : ""}</>,
    },
    {
      header: "Total price",
      accessor: "totalSalePrice",
      callback: (price: number) => (
        <>{price ? <Box fontWeight="bold">${price}</Box> : ""}</>
      ),
    },
    {
      header: "",
      accessor: "id",
      callback: (jobId: string) => (
        <Image
          src="/assets/trash-red.svg"
          alt="Zolo Logo"
          width="24px"
          height="24px"
          _hover={{
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setDeleteJobModal({ id: jobId, open: true });
          }}
        />
      ),
    },
  ];

  return (
    <Flex
      bg="white"
      boxShadow="base"
      borderRadius="md"
      flexDir="row"
      justifyContent="space-between"
      overflowY="auto"
      w="100%"
    >
      <Flex w="100%" flexDirection="column">
        <Flex flexDir="row" p={4}>
          <CardTitle>Jobs ({jobs?.length ?? 0})</CardTitle>
        </Flex>
        <Box overflowX="auto" px="16px">
          <Table size="sm" w="100%" variant="simple">
            <Thead>
              <Tr>
                {columns.map((column, i) => (
                  <Th color="brand.green" key={i}>
                    {column.header}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {jobs.map((row, i) => (
                <Tr
                  _hover={{
                    cursor: "pointer",
                    backgroundColor: "lightGray",
                  }}
                  onClick={() => {
                    navigate(`/app/job/${row.id}`);
                  }}
                  key={i}
                >
                  {columns.map((column, j) => (
                    <Td key={j} height="40px">
                      {column.callback ? (
                        <>{column.callback(row[column.accessor])}</>
                      ) : (
                        <>{row[column.accessor]}</>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Flex>

      <AlertDialog
        isOpen={deleteJobModal.open}
        onClose={() => setDeleteJobModal({ id: "", open: false })}
        title="Confirmation required"
        colorScheme="red"
        confirmText="Delete"
        onConfirm={deleteJob}
      >
        This action is not reversible - are you sure you want to delete this job?
      </AlertDialog>
    </Flex>
  );
};
