import QRCode from "react-qr-code";
import { Box, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { onQrDownload, onQrPrint } from "../../util/qr.util";
import { DownloadIcon } from "@chakra-ui/icons";
import PrintIcon from "@mui/icons-material/Print";

export const QrCustom = ({ number, id, showIcons = true }) => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" gap="24px">
      <Box
        justifyContent="center"
        textAlign="center"
        id="QRCode"
        className="QRCode"
        w="80px"
        h="80px"
      >
        <QRCode
          fgColor="#265542"
          style={{
            maxWidth: "80px",
            maxHeight: "80px",
          }}
          value={number.toString()}
        />
        <Text as={"p"}>{number}</Text>
      </Box>
      <Flex hidden={!showIcons} justifyContent="flex-end" gap="6px">
        <Tooltip label="Print">
          <IconButton
            variant="ghost"
            aria-label="Print"
            size="sm"
            icon={<PrintIcon fontSize="small" />}
            onClick={() => onQrPrint()}
          />
        </Tooltip>
        <Tooltip label="Download">
          <IconButton
            variant="ghost"
            aria-label="Download"
            size="sm"
            icon={<DownloadIcon />}
            onClick={() => onQrDownload()}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
