import { Flex, Box, Image, Badge, Tooltip, useToast } from "@chakra-ui/react";
import { CardTitle } from "../../../shared/CardTitle";
import CancelIcon from "@mui/icons-material/Cancel";
import { SendIcon } from "../../../shared/icons/Icons";
import AlertDialog from "../../../shared/AlertDialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { userService } from "../../../../service/user/user.service";
import { errorToast, successToast } from "../../../../constants/toast.constants";

export const CompanyDetailUserTable = ({ users, onDelete }) => {
  const [deleteUserAlertOpen, setDeleteUserAlertOpen] = useState({id: null, open: false});

  const toast = useToast();
  const profile = useSelector((state: RootState) => state.userProfile);
  const { email, isManager, photoUri } = profile;
  
  const deleteFromCompany = async () => {
    await userService.deleteCompanyUser(deleteUserAlertOpen.id).then(() => {
      toast(successToast('User deleted successfully'));
      onDelete();
    }).catch(() => {
      toast(errorToast(('Something went wrong. Try again.')))      
    })
  }

  return (
    <><Flex
      bg="white"
      boxShadow="base"
      borderRadius="md"
      overflowY="auto"
      flexDir="row"
      justifyContent="space-between"
      width="100%"
    >
      <Flex w="100%" flexDirection="column">
        <Flex flexDir="row" p={4}>
          <CardTitle>Users ({users?.length ?? 0})</CardTitle>
        </Flex>
        {users
          ?.sort((a, b) => a.deleted - b.deleted)
          .map((row, i) => (
            <Flex flexDir="row" p={4} justifyContent="space-between" alignItems='center'>
              <Flex flexDir="row" gap="4px" alignItems="center">
                {row.deleted ? (
                  <CancelIcon style={{ fontSize: "28px", color: "#A0AEBF" }} />
                ) : (
                  <Image
                    src="/assets/check.svg"
                    alt="Zolo Logo"
                    width="20px"
                    height="20px"
                    mt="4px" />
                )}
                <Badge colorScheme={row.role === "admin" ? "green" : "gray"} w="fit-content">
                  {row.role === "admin" ? "ADMIN" : "STANDARD"}
                </Badge>

                {row.email}
              </Flex>

              {isManager && (

              <Flex flexDir="row" gap="12px" alignItems="center">
                <Tooltip label="Move Companies" aria-label="edit">
                  <Box cursor="pointer" onClick={() => alert('Moving users coming soon')}>
                    <SendIcon />
                  </Box>
                </Tooltip>
                <Tooltip label="Delete" aria-label="edit">
                  <Box cursor="pointer" onClick={() => alert('Moving users coming soon')}>
                    <Image
                      src="/assets/trash-red.svg"
                      alt="Zolo Logo"
                      width="24px"
                      height="24px"
                      _hover={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteUserAlertOpen({id: row.id, open: true});
                      }} />
                  </Box>
                </Tooltip>
              </Flex>
              )}
            </Flex>
          ))}
      </Flex>
    </Flex>
    <AlertDialog
      isOpen={deleteUserAlertOpen.id}
      onClose={() => setDeleteUserAlertOpen({id: null, open: false})}
      title="Danger ahead"
      colorScheme="red"
      confirmText="Delete"
      onConfirm={() => {
        setDeleteUserAlertOpen({id: null, open: false});
        deleteFromCompany();
      }}
    >
        You can't undo this action. <br /> The user will be permanently deleted, and so will any associated records.
      </AlertDialog></>
  );
};
