import { createSlice } from "@reduxjs/toolkit";

export interface Token {
  accessToken: string;
}

export const initialState: Token = {
  accessToken: "",
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    update: (state, params) => {
      const f = params.payload as any;
      state.accessToken = f.accessToken;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = tokenSlice.actions;

export default tokenSlice.reducer;
