import { Flex, Grid, ModalFooter, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { TextAreaInput } from "../../form/controls/text-area.input";
import { useEffect, useState } from "react";
import { DropoffService } from "../../../service/company/dropoff.service";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { JobStatus } from "../../../models/enum/job-status.enum";
import { CompanyService } from "../../../service/company/company.service";
import { JobService } from "../../../service/job/job.service";
import { UserService } from "../../../service/user/user.service";
import { FormInput } from "../../form/controls/form.input";
import { CheckboxInput } from "../../form/controls/checkbox.input";

export const EditJobModal = ({ isOpen, onClose, onSubmit, job }) => {
  const companyService = new CompanyService();
  const jobService = new JobService();
  const userService = new UserService();

  const toast = useToast();
  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [companyDropdown, setCompanyDropdown] = useState([] as any);
  const [userDropdown, setUserDropdown] = useState([] as any);
  const [adminUserDropdown, setAdminUserDropdown] = useState([] as any);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const obj = { ...job, ...formState };

    await jobService
      .update({
        id: obj.id,
        status: obj.status,
        startDate: obj.startDate,
        notes: obj.notes,
        companyId: obj.companyId,
        weight: obj.weight,
        contactUserId: obj.contactUserId,
        rebateRequired: obj.rebateRequired,
        rebateReady: obj.rebateReady,
        assignedAdminId: obj.assignedAdminId,
        jobNumber: obj.jobNo,
      })
      .then(() => {
        toast(successToast("Job edited successfully."));
        handleClose();
        onSubmit({ ...job, ...formState });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({});
    onClose();
  };

  const initDropdowns = async () => {
    const dropdown = await companyService.getListForDropdown();
    setCompanyDropdown(dropdown);

    const users = await userService.getCompanyUsers(job.companyId);
    let obj = {};

    users.forEach((user) => {
      if (!user.deleted) {
        obj[user.id] = `${user.firstName} ${user.lastName} <${user.email}>`;
      }
    });

    setUserDropdown(obj);
  };

  const initAdminUserDropdown = async () => {
    const users = await userService.getUsers();
    let obj = {};

    users
      .filter((x) => !x.deleted)
      .forEach((user) => {
        obj[user.id] = `${user.email}`;
      });

    setAdminUserDropdown(obj);
  };

  useEffect(() => {
    initAdminUserDropdown();
  }, []);

  useEffect(() => {
    if (job.companyId) {
      initDropdowns();
    }
    return () => {};
  }, [job.companyId]);

  return (
    <Modal
      isOpen={isOpen}
      header={`Edit JOB-${job.jobNo}`}
      onClose={handleClose}
      width="650px"
    >
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Grid templateColumns="repeat(2, 1fr)" gap={2} rowGap={6}>
            <DatepickerInput
              title="Start date"
              name="startDate"
              initialDate={new Date(job.startDate)}
              onChange={onInputChange}
            />
            <DropdownInput
              name="status"
              title="Status"
              options={JobStatus}
              defaultValue={job.status}
              onChange={onInputChange}
            />
            <DropdownInput
              required
              name="companyId"
              title="Company"
              options={companyDropdown}
              defaultValue={job.companyId}
              disabled
            />
            <DropdownInput
              name="contactUserId"
              title="Contact user"
              options={userDropdown}
              defaultValue={job.contactUserId}
              onChange={onInputChange}
            />
            <DropdownInput
              name="assignedAdminId"
              title="ZOLO Champion"
              options={adminUserDropdown}
              defaultValue={job.assignedAdminId}
              onChange={onInputChange}
            />
            <FormInput
              required
              name="weight"
              title="Weight (kg)"
              type="number"
              defaultValue={job.weight}
              onChange={onInputChange}
            />
          </Grid>
          <TextAreaInput
            title="Additional info"
            name="notes"
            defaultValue={job.notes}
            onChange={onInputChange}
          />
          <CheckboxInput
            name="rebateRequired"
            title="Rebate required"
            defaultChecked={job.rebateRequired}
            onChange={onInputChange}
          />
          <CheckboxInput
            name="rebateReady"
            title="Rebate ready"
            defaultChecked={job.rebateReady}
            onChange={onInputChange}
          />

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};
