import { Flex, ModalFooter, useToast, Text, Box } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { TextAreaInput } from "../../form/controls/text-area.input";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { FormInput } from "../../form/controls/form.input";
import { CompanyService } from "../../../service/company/company.service";

export const CarbonCreditsModal = ({
  isOpen,
  onClose,
  onSubmit,
  balance,
  companyId,
}) => {
  const companyService = new CompanyService();
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await companyService
      .updateCarbonCredits({ id: companyId, ...formState })
      .then(() => {
        toast(successToast("Carbon Credits updated successfully."));
        handleClose();
        onSubmit(formState.carbonCredits);
      })
      .catch(() => {
        toast(errorToast("Error updating credits."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({});
    onClose();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal isOpen={isOpen} header="Update credit balance" onClose={handleClose}>
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Box
            px="24px"
            py="4px"
            w="fit-content"
            color="white"
            borderRadius="4px"
            bg="brand.green"
          >
            Balance: {balance}
          </Box>
          <FormInput
            required
            name="carbonCredits"
            title="Update balance"
            type="number"
            onChange={onInputChange}
          />

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};
