import {
  Badge,
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { CardCustom } from "../../shared/CardCustom";
import { ButtonCustom } from "../../form/controls/form.button";
import { AddFieldModal } from "./add-field.modal";
import { useEffect, useState } from "react";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReorderIcon from "@mui/icons-material/Reorder";
import { FormBuilderService } from "../../../service/forge/form-builder.service";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const FormEditor = ({ form, isDefault = false }) => {
  const formbuilderService = new FormBuilderService();

  const [addFieldModalOpen, setAddFieldModalOpen] = useState(false);
  const [editObject, setEditObject] = useState(null);
  const toast = useToast();

  const [json, setJson] = useState([]);

  const handleAddField = (field) => {
    const copy = JSON.parse(JSON.stringify(json)) ?? [];
    const obj = {
      label: field.label,
      name: `zolo_custom_${field.label.split(" ").join("_").toLowerCase()}`,
      type: field.type,
      required: field.required,
      options: field.options,
    };

    copy.push(obj);
    setJson(copy);
    handleSubmit(copy);

    setAddFieldModalOpen(false);
  };

  const handleDragEnd = (result) => {
    if (isDefault) {
      toast(errorToast("You cannot reorder default fields"));
      return;
    }

    if (!result.destination) return;
    const items = Array.from(json);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setJson(items);
    handleSubmit(items);
  };

  const handleEdit = (newField) => {
    const copy = JSON.parse(JSON.stringify(json));
    const index = copy.findIndex((field) => field.name === editObject.name);

    if (index !== -1) {
      if (newField.systemField) {
        copy[index] = {
          ...copy[index],
          required: newField.required,
          options: newField.options,
        };
      } else {
        copy[index] = {
          label: newField.label,
          name: copy[index].name, // keep the existing name
          type: newField.type,
          required: newField.required,
          options: newField.options,
        };
      }

      setJson(copy);
      handleAddFieldClose();
      handleSubmit(copy);
    } else {
      toast(errorToast("Issue setting a new field"));
    }
  };

  const handleSubmit = async (json: any[]) => {
    const obj = {
      id: form.id,
      formJson: JSON.stringify(json),
    };

    await formbuilderService.updateFormJson(obj).then((res) => {
      toast(successToast("Form saved successfully"));
    });
  };

  const handleDelete = (label: string) => {
    const copy = JSON.parse(JSON.stringify(json));
    const index = copy.findIndex((field) => field.label === label);

    if (index !== -1) {
      copy.splice(index, 1);
      setJson(copy);
      handleSubmit(copy);
      toast(successToast("Field deleted successfully"));
    } else {
      toast(errorToast("Issue deleting a field"));
    }
  };

  const handleAddFieldClose = () => {
    setEditObject(null);
    setAddFieldModalOpen(false);
  };

  useEffect(() => {
    setJson(form.formJson);
  }, [form.formJson]);

  return (
    <>
      <CardCustom width="70%">
        <Flex flexDir="column" gap="16px">
          <Flex
            alignItems="center"
            flexDir="row"
            justifyContent="space-between"
          >
            <Box color="brand.green" fontSize="20px" fontWeight="bold">
              {isDefault ? "Default fields" : "Add or remove fields"}
            </Box>
            {!isDefault && (
              <Flex flexDir="row" gap="8px">
                <ButtonCustom
                  neutral
                  onClick={() => setAddFieldModalOpen(true)}
                >
                  Add field
                </ButtonCustom>
              </Flex>
            )}
          </Flex>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="json">
              {(provided) => (
                <Flex
                  flexDir="column"
                  gap="16px"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {json?.map((x, index) => (
                    <Draggable
                      key={x.label}
                      draggableId={x.label}
                      index={index}
                    >
                      {(provided) => (
                        <Flex
                          flexDir="row"
                          justifyContent="space-between"
                          border="1px solid lightGray"
                          key={x.id}
                          bg="white"
                          p="16px"
                          borderRadius="8px"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Flex key={x.label} flexDir="row" gap="8px">
                            <ReorderIcon />
                            <Box fontWeight="bold" color="brand.green">
                              {x.label}
                            </Box>
                            <Tooltip label="This field is mandatory">
                              <Box color="brand.brown">
                                {x.required && <ErrorOutlineRoundedIcon />}
                              </Box>
                            </Tooltip>
                          </Flex>
                          <Flex cursor="pointer" flexDir="row" gap="8px">
                            <Badge colorScheme="gray" h="fit-content">
                              {x.type}
                            </Badge>
                            {!isDefault && (
                              <>
                                <EditNoteOutlinedIcon
                                  onClick={() => {
                                    setEditObject(x);
                                    setAddFieldModalOpen(true);
                                  }}
                                />
                                {!x.systemField && (
                                  <DeleteIcon
                                    onDelete={() => handleDelete(x.label)}
                                  />
                                )}
                              </>
                            )}
                          </Flex>
                        </Flex>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
      </CardCustom>
      <AddFieldModal
        isOpen={addFieldModalOpen}
        onClose={handleAddFieldClose}
        onSubmit={editObject ? handleEdit : handleAddField}
        field={editObject}
        systemField={editObject?.systemField}
      />
    </>
  );
};

const DeleteIcon = ({ onDelete }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <DeleteForeverIcon />
      </PopoverTrigger>
      <Portal>
        <PopoverContent p="16px">
          <PopoverArrow />
          <PopoverCloseButton />
          <Flex flexDir="row" alignItems="center" gap="26px">
            <Box>Are you sure?</Box>
            <ButtonCustom
              style={{ width: "fit-content" }}
              danger
              onClick={onDelete}
            >
              Delete
            </ButtonCustom>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
