import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CustomFormModel } from "../../../models/form-builder/custom-form.model";
import { FormBuilderService } from "../../../service/forge/form-builder.service";
import { useParams } from "react-router-dom";
import { BasicDetails } from "../../../components/app/forge/BasicDetails";
import { FormEditor } from "../../../components/app/forge/FormEditor";

export const FormBuilder = () => {
  const formService = new FormBuilderService();
  const { id } = useParams();

  const [form, setForm] = useState({} as CustomFormModel);

  const init = async () => {
    await formService.get(id).then((res) => {
      setForm(res);
    });
  };

  useEffect(() => {
    init();
  }, [id]);

  return (
    <>
      <Flex flexDir="column" gap="24px" pt="30px" alignItems="center">
        <BasicDetails form={form} />
        <FormEditor form={form} isDefault={form.isDefault} />
      </Flex>
    </>
  );
};
