import { Flex, ButtonGroup } from "@chakra-ui/react";
import { useState } from "react";
import { ButtonCustom } from "../../../form/controls/form.button";
import { CompanyDetailJobTable } from "./job-table.component";
import { CompanyDetailUserTable } from "./user-table.component copy";
import { CompanyDetailBranchTable } from "./branch-table.component";

export const CompanyDetailAdditionalInfo = ({
  jobs,
  users,
  branches = undefined,
  onDeleteJob,
  onDeleteUser,
}) => {
  const [displayedTable, setDisplayedTable] = useState("jobs"); // Set default table

  const handleToggle = (table) => {
    setDisplayedTable(table);
  };

  return (
    <Flex flexDir="column" gap="16px" w="100%" mt="30px">
      <ButtonGroup>
        <ButtonCustom
          neutral={displayedTable !== "jobs"}
          onClick={() => handleToggle("jobs")}
        >
          Jobs
        </ButtonCustom>
        <ButtonCustom
          neutral={displayedTable !== "users"}
          onClick={() => handleToggle("users")}
        >
          Users
        </ButtonCustom>
        {!!branches?.length && (
          <ButtonCustom
            neutral={displayedTable !== "branches"}
            onClick={() => handleToggle("branches")}
          >
            Branches
          </ButtonCustom>
        )}
      </ButtonGroup>
      {displayedTable === "jobs" && <CompanyDetailJobTable jobs={jobs} onDelete={onDeleteJob} />}
      {displayedTable === "users" && <CompanyDetailUserTable users={users} onDelete={onDeleteUser} />}
      {displayedTable === "branches" && (
        <CompanyDetailBranchTable companies={branches} />
      )}
    </Flex>
  );
};
