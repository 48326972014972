import { Role } from "../../models/enum/role.enum";
import { BaseService } from "../base.service";

export class IdentityService extends BaseService {
  constructor() {
    super();
  }

  async login(didToken: string) {
    const resp = await this.client.post(
      "/super/identity/user/login",
      {},
      {
        headers: { Authorization: `Bearer ${didToken}` },
      }
    );

    return resp.data;
  }
}
