import { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { InventoryBulkFilter } from "../../../models/filter/inventory-bulk.filter";
import { InventoryModel } from "../../../models/inventory.model";
import {
  updateFilter as inventoryUpdate,
  updatePageSize,
} from "../../../store/slice/Account/inventory.slice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks.util";
import { downloadCsvToMachine } from "../../../util/file.util";
import { ButtonCustom } from "../../../components/form/controls/form.button";
import { InventoryTable } from "../../../components/app/inventory/inventory.table";
import { DownloadIcon } from "@chakra-ui/icons";
import { TableFooter } from "../../../components/shared/table/TableFooter";
import { Loading } from "../../../components/shared/Loading";
import { ToggleInput } from "../../../components/form/controls/toggle.input";
import { FormInput } from "../../../components/form/controls/form.input";
import { UploadInventoryModal } from "../../../components/app/inventory/upload-inventory.modal";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useSearchParams } from "react-router-dom";

export const Inventory = ({}) => {
  const inventoryService = new InventoryService();
  const dispatch = useAppDispatch();

  const { filter, sortColumn, pageSize } = useAppSelector(
    (state) => state.inventory
  );

  const [loading, setLoading] = useState(true);
  const [inventory, setInventory] = useState({
    data: [],
    totalItems: 0,
    totalPage: 0,
  } as { data: InventoryModel[]; totalPage: number; totalItems: number });

  const [pageNum, setPageNum] = useState(1);
  const [checkedIds, setCheckedIds] = useState([] as string[]);
  const [showSold, setShowSold] = useState(false);
  const [searchParams] = useSearchParams();
  const [uploadOpen, setUploadOpen] = useState(false);

  const [globalSearchTerm, setGlobalSearchTerm] = useState("");
  const [activeInventory, setActiveInventory] = useState(null);
  const [searchParamReady, setSearchParamReady] = useState(false);

  useEffect(() => {
    if (searchParams.get("inventory_id")) {
      setActiveInventory(searchParams.get("inventory_id"));
    }
    if (searchParams.get("searchTerm")) {
      setGlobalSearchTerm(searchParams.get("searchTerm"));
    }

    setSearchParamReady(true);
  }, [searchParams]);

  const init = async (
    page?: number,
  ) => {
    setLoading(true);
    const filterPayload = filter
      ? { ...filter, searchTerm: globalSearchTerm }
      : { searchTerm: globalSearchTerm };

    await inventoryService
      .getList({ ...filterPayload, ids: activeInventory ? [activeInventory] : undefined }, true, page ?? pageNum, pageSize, sortColumn)
      .then((inventory) => {
        setInventory(inventory);
      })
      .finally(() => setLoading(false));
  };

  const handleExport = async () => {
    setLoading(true);
    await inventoryService
      .exportToCsv({
        ids: !!checkedIds?.length ? checkedIds : undefined,
        ...filter,
      })
      .then((csvData) => {
        downloadCsvToMachine(csvData, "inventory-export.csv");
      })
      .finally(() => setLoading(false));
  };

  const handleshowSold = () => {
    const newFilter = {
      ...filter,
      status: !showSold ? "SOLD" : null,
    };

    setShowSold(!showSold);
    setPageNum(1);
    dispatch(inventoryUpdate(newFilter));
  };

  useEffect(() => {
    if (searchParamReady) {
      init();
    }
  }, [globalSearchTerm, searchParamReady, filter, sortColumn, pageSize]);

  return (
    <>
      <Loading loading={loading} ignoreDelay />
      <Flex
        flexDir="row"
        justifyContent="flex-end"
        alignItems="center"
        gap="8px"
      >
        {/* <Box w="300px" mr="20px" mb="12px">
          <FormInput
            name=""
            title=""
            placeholder="Search inventory..."
            onChange={handleInputChange}
          />
        </Box> */}
        <ToggleInput
          rightLabel="Show sold"
          defaultChecked={filter?.status === "SOLD"}
          onChange={handleshowSold}
        />
        <Flex flexDir="row" gap="6px" justifyContent="flex-end">
          <ButtonCustom neutral secondary onClick={() => setUploadOpen(true)}>
            Upload
            <UploadFileIcon />
          </ButtonCustom>
          <ButtonCustom
            disabled={!inventory.data?.length}
            onClick={handleExport}
          >
            Export
            <DownloadIcon ml="6px" />
          </ButtonCustom>
        </Flex>
      </Flex>

      {!loading && (
        <InventoryTable
          key={inventory.data.length}
          inventory={inventory.data}
          onDelete={(id: string) =>
            setInventory({
              data: inventory.data.filter((i) => i.id !== id),
              totalPage: inventory.totalPage,
              totalItems: inventory.totalItems,
            })
          }
          onEdit={init}
          onCheckChange={(data) =>
            setCheckedIds(data.filter((x) => x.isChecked).map((x) => x.id))
          }
          onFilterChange={(filter: InventoryBulkFilter) => {
            setPageNum(1);
            dispatch(inventoryUpdate(filter));
          }}
          onSort={() => setPageNum(1)}
          footer={
            <TableFooter
              count={inventory.data?.length}
              totalItems={inventory.totalItems}
              totalPage={inventory.totalPage}
              pageNum={pageNum}
              onChange={(num) => {
                setPageNum(num);
                init(num);
              }}
              pageSize={pageSize}
              onPageSizeChange={(size) => {
                setPageNum(1);
                dispatch(updatePageSize({ pageSize: size }));
              }}
            />
          }
        />
      )}

      <UploadInventoryModal
        isBulkEdit
        isOpen={uploadOpen}
        onClose={() => setUploadOpen(false)}
        onSubmit={() => {
          setPageNum(1);
          init(1);
        }}
      />
    </>
  );
};
