import {
  Box,
  Flex,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  VStack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, destroyAllState } from "../../store";
import { useEffect, useRef, useState } from "react";
import { magiclinkService } from "../../service/magiclink/magiclink.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "../shared/Loading";
import { RepeatIcon } from "@chakra-ui/icons";
import { BlanccoSyncModal } from "./inventory/blancco-sync.modal";
import { blanccoService } from "../../service/integrations/blancco.service";
import { convertUTCtoEnAU } from "../../util/date.util";
import { companyService } from "../../service/company/company.service";
import { FormInput } from "../form/controls/form.input";
import { useOutsideClick } from "../hooks/hooks/click-outside.hook";

const titles = [
  { header: "pickup requests", uri: "dropoff" },
  { header: "jobs", uri: "job" },
  { header: "inventory items", uri: "inventory" },
  { header: "companies", uri: "company" },
  { header: "forge", uri: "forge" },

  { header: "account details", uri: "account" },
  { header: "your zolosystem", uri: "dashboard" },
];

const Header: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email } = useSelector((state: RootState) => state.userProfile);

  const wrapperRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [lastSync, setLastSync] = useState("");

  const [syncOpen, setSyncOpen] = useState(false);
  const [globalSearchResults, setGlobalSearchResults] = useState(
    [] as { title: string; type: "job" | "inventory" | "branch"; id: string }[]
  );

  const [inventorySearchResults, setInventorySearchResults] = useState(
    [] as { title: string; id: string; job_id: string }[]
  );
  const [branchSearchResults, setBranchSearchResults] = useState(
    [] as { title: string; id: string }[]
  );
  const [companySearchResults, setCompanySearchResults] = useState(
    [] as { title: string; id: string }[]
  );
  const [jobSearchResults, setJobSearchResults] = useState(
    [] as { title: string; id: string }[]
  );

  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
  const [hasSearchParams, setHasSearchParams] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  const clear = async () => {
    setIsClearing(true);
    setSearchTerm("");
    setSearchDropdownOpen(false);
    searchParams.delete("searchTerm");
    searchParams.delete("inventory_id");
    setHasSearchParams(false);
    navigate(window.location.pathname);

    // reload window after 3 second delay
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    const term = searchParams.get("searchTerm");
    const inventoryId = searchParams.get("searchTerm");

    if (term) {
      setSearchTerm(term);
    }

    setHasSearchParams(!!term || !!inventoryId);
  }, [searchParams]);

  const logout = async () => {
    setLoading(true);
    await magiclinkService
      .logout()
      .then(() => {
        destroyAllState();
      })
      .finally(() => {
        navigate("/app/auth/login");
        setLoading(false);
      });
  };

  const fetchLastSync = async () => {
    await blanccoService.getLastSync().then((resp) => {
      setLastSync(resp);
    });
  };

  const handleGlobalSearch = async (e: any) => {
    const { value } = e.target;

    setSearchTerm(value);

    await companyService.globalSearch(value).then((res) => {
      setBranchSearchResults(res.data.filter((item) => item.type === "branch"));
      setInventorySearchResults(
        res.data.filter((item) => item.type === "inventory")
      );
      setJobSearchResults(res.data.filter((item) => item.type === "job"));
      setCompanySearchResults(
        res.data.filter((item) => item.type === "company")
      );
      setGlobalSearchResults(res.data);
    });

    setSearchDropdownOpen(true);
  };

  const getEntityColor = (type: "job" | "inventory" | "branch" | "company") => {
    switch (type) {
      case "job":
        return "#8BBFEA";
      case "inventory":
        return "#A0CCA2";
      case "branch":
      case "company":
        return "#F6D981";
      default:
        return "#F6D981";
    }
  };

  useOutsideClick(wrapperRef, () => {
    setSearchDropdownOpen(false);
  });

  useEffect(() => {
    fetchLastSync();
    const currentPath = window.location.pathname;

    for (let t of titles) {
      if (currentPath.endsWith(t.uri)) {
        setTitle(t.header);
        break;
      }
    }
  }, [window.location.pathname]);

  return (
    <>
      <Flex
        ref={wrapperRef}
        h="60px"
        w="100%"
        bg="white"
        position="absolute"
        zIndex={5}
        flexDir="row"
        alignItems="center"
        justifyContent="flex-end"
        pr="36px"
        pl="calc(36px + 230px)"
        borderBottom="1px solid lightgray"
      >
        <Loading loading={loading} fill />
        <Flex
          w="100%"
          flexDir="row"
          alignItems="center"
          gap="6px"
          justifyContent="space-between"
        >
          <Box mr="100px"></Box>

          <Box w="600px" pos="relative" marginRight="100px">
            <FormInput
              name={"globalSearch"}
              title={""}
              value={searchTerm}
              placeholder={isClearing ? "Clearing..." : "Start typing to find your jobs, inventory, and more..."}
              onChange={handleGlobalSearch}
              rightAddon={
                <Flex
                  hidden={!hasSearchParams || isClearing}
                  ml="4px"
                  justifyContent="center"
                  cursor="pointer"
                  alignItems="center"
                  w="80px"
                  bg="brand.green"
                  borderRadius="8px"
                  color="white"
                  fontWeight="600"
                  onClick={clear}
                >
                  Clear
                </Flex>
              }
              onFocus={(e) => {
                if (!!searchTerm?.length) handleGlobalSearch(e);
              }}
            ></FormInput>

            {searchDropdownOpen && (
              <>
                <>
                  <VStack
                    pos="absolute"
                    w="600px"
                    align="stretch"
                    mt="2"
                    boxShadow="sm"
                    border="1px solid #E2E8F0"
                    borderRadius="md"
                    bg="white"
                    px="3"
                    py="4"
                  >
                    {globalSearchResults.length ? (
                      <>
                        {companySearchResults.length === 5 && (
                          <Flex
                            cursor="pointer"
                            fontSize="12px"
                            justifyContent="flex-end"
                          >
                            Top 5 showing
                          </Flex>
                        )}
                        {companySearchResults.map((item, index) => (
                          <>
                            <Flex
                              py="1"
                              px="2"
                              borderBottom={
                                index === companySearchResults.length - 1
                                  ? "1px solid #E2E8F0"
                                  : ""
                              }
                              flexDir="row"
                              key={item.id}
                              justifyContent="space-between"
                              _hover={{
                                cursor: "pointer",
                                bg: "gray.200",
                              }}
                              borderRadius="4px"
                              onClick={() => {
                                // Make this open in a new tab
                                window.open(
                                  `/app/company/${item.id}?searchTerm=${searchTerm}`,
                                  "_blank"
                                );
                              }}
                            >
                              <Box>{item.title}</Box>
                              <Box
                                fontSize="12px"
                                fontWeight="600"
                                h="20px"
                                px="12px"
                                bg={getEntityColor("company")}
                                borderRadius="6px"
                              >
                                Company
                              </Box>
                            </Flex>
                          </>
                        ))}
                        {branchSearchResults.length === 5 && (
                          <Flex
                            cursor="pointer"
                            fontSize="12px"
                            justifyContent="flex-end"
                          >
                            Top 5 showing
                          </Flex>
                        )}
                        {branchSearchResults.map((item, index) => (
                          <>
                            <Flex
                              py="1"
                              px="2"
                              borderBottom={
                                index === branchSearchResults.length - 1
                                  ? "1px solid #E2E8F0"
                                  : ""
                              }
                              flexDir="row"
                              key={item.id}
                              justifyContent="space-between"
                              _hover={{
                                cursor: "pointer",
                                bg: "gray.200",
                              }}
                              borderRadius="4px"
                              onClick={() => {
                                // Make this open in a new tab
                                window.open(
                                  `/app/company/${item.id}?searchTerm=${searchTerm}`,
                                  "_blank"
                                );
                              }}
                            >
                              <Box>{item.title}</Box>
                              <Box
                                fontSize="12px"
                                fontWeight="600"
                                h="20px"
                                px="12px"
                                bg={getEntityColor("branch")}
                                borderRadius="6px"
                              >
                                Branch
                              </Box>
                            </Flex>
                          </>
                        ))}
                        {jobSearchResults.length === 5 && (
                          <Flex
                            cursor="pointer"
                            fontSize="12px"
                            justifyContent="flex-end"
                          >
                            Top 5 showing
                          </Flex>
                        )}
                        {jobSearchResults.map((item, index) => (
                          <>
                            <Flex
                              py="1"
                              px="2"
                              flexDir="row"
                              key={item.id}
                              justifyContent="space-between"
                              borderBottom={
                                index === jobSearchResults.length - 1
                                  ? "1px solid #E2E8F0"
                                  : ""
                              }
                              _hover={{
                                cursor: "pointer",
                                bg: "gray.200",
                              }}
                              borderRadius="4px"
                              onClick={() => {
                                window.open(
                                  `/app/job/${item.id}?searchTerm=${searchTerm}`,
                                  "_blank"
                                );
                              }}
                            >
                              <Box>JOB-{item.title}</Box>
                              <Box
                                fontSize="12px"
                                fontWeight="600"
                                h="20px"
                                px="12px"
                                bg={getEntityColor("job")}
                                borderRadius="6px"
                              >
                                Job
                              </Box>
                            </Flex>
                          </>
                        ))}

                        {!!inventorySearchResults.length && (
                          <Flex
                            cursor="pointer"
                            fontSize="12px"
                            justifyContent="flex-end"
                            _hover={{ textDecoration: "underline" }}
                            onClick={() => {
                              // Make this open in a new tab
                              window.open(
                                `/app/inventory?searchTerm=${searchTerm}`,
                                "_blank"
                              );
                            }}
                          >
                            View filtered results
                          </Flex>
                        )}
                        {inventorySearchResults.map((item, index) => (
                          <>
                            <Flex
                              py="1"
                              px="2"
                              flexDir="row"
                              key={item.id}
                              justifyContent="space-between"
                              _hover={{
                                cursor: "pointer",
                                bg: "gray.200",
                              }}
                              borderRadius="4px"
                              onClick={() => {
                                // Make this open in a new tab
                                window.open(
                                  `/app/inventory?inventory_id=${item.id}&searchTerm=${searchTerm}`,
                                  "_blank"
                                );
                              }}
                            >
                              <Box>#QR_{item.title}</Box>
                              <Box
                                fontSize="12px"
                                fontWeight="600"
                                h="20px"
                                px="12px"
                                bg={getEntityColor("inventory")}
                                borderRadius="6px"
                              >
                                Inventory
                              </Box>
                            </Flex>
                          </>
                        ))}
                      </>
                    ) : (
                      <>Nothing to see</>
                    )}
                  </VStack>
                </>
              </>
            )}
          </Box>

          <Flex flexDir="row" gap="16px" alignItems="center">
            <Flex
              cursor="pointer"
              _hover={{ color: "brand.green" }}
              onClick={() => setSyncOpen(true)}
              flexDir="row"
              gap="6px"
              alignItems="center"
            >
              <RepeatIcon />
              Last sync: {lastSync ? convertUTCtoEnAU(lastSync) : "Never"}
            </Flex>
            <Menu>
              {/* <MenuButton
                as={SettingsIcon}
                color="brand.gray.400"
                fontSize="20px"
                sx={{ background: "none", color: "#8f8d88", cursor: "pointer" }}
                _hover={{ bg: "none" }}
                _expanded={{ bg: "none" }}
                _focus={{ bg: "none" }}
              /> */}
              <MenuList>
                <MenuGroup title={email}>
                  <MenuDivider />
                  <MenuItem
                    onClick={() => navigate("/app/account")}
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                  >
                    Account
                  </MenuItem>
                  <MenuItem
                    onClick={logout}
                    sx={{ fontWeight: "bold", color: "red", fontSize: "14px" }}
                  >
                    Logout
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      <BlanccoSyncModal isOpen={syncOpen} onClose={() => setSyncOpen(false)} />
    </>
  );
};

export default Header;
