import { createSlice } from "@reduxjs/toolkit";

export interface UserProfile {
  name: string;
  email: string;
  photoUri?: string;
  role?: string;
  isManager: boolean;
}

export const initialState: UserProfile = {
  name: "",
  email: "",
  isManager: false,
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    update: (state, params) => {
      const f = params.payload as UserProfile;

      state.email = f.email;
      state.photoUri = f.photoUri;
      state.name = f.name;
      state.role = f.role;
      state.isManager = f.isManager;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = userProfileSlice.actions;

export default userProfileSlice.reducer;
