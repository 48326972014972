import { Flex } from "@chakra-ui/react";
import { CustomFieldModel } from "../../../models/form-builder/custom-field.model";
import { CustomFieldType } from "../../../models/form-builder/custom-field-type.enum";
import { FormInput } from "../../form/controls/form.input";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { TextAreaInput } from "../../form/controls/text-area.input";
import { transformArrayToObject } from "../../../util/object.util";
import { convertToEnumKey } from "../../../util/string.util";
import { useEffect, useState } from "react";
import { UserService } from "../../../service/user/user.service";

export const InventoryCustomForm = (props: {
  json: CustomFieldModel[];
  children: any;
  onInputChange: (e: any) => void;
  initialState?: any;
}) => {
  const { json, onInputChange, children, initialState } = props;
  const userService = new UserService();

  const [adminUserDropdown, setAdminUserDropdown] = useState([] as any);
  const [assignedAdminId, setAssignedAdminId] = useState("");
  const [formState, setFormState] = useState({} as any);

  const initAdminUserDropdown = async () => {
    const users = await userService.getUsers();
    let obj = {};

    users
      .filter((x) => !x.deleted)
      .forEach((user) => {
        obj[user.id] = `${user.email}`;
      });

    setAdminUserDropdown(obj);
  };

  useEffect(() => {
    initAdminUserDropdown();
  }, []);

  useEffect(() => {
    if (initialState?.assignedAdminId) {
      setAssignedAdminId(initialState.assignedAdminId);
    }
  }, [initialState]);

  useEffect(() => {
    setFormState({
      ...initialState,
    });
  }, [initialState]);

  return (
    <>
      <Flex
        p="12px"
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap="6px"
      >
        <DropdownInput
          name="assignedAdminId"
          title="ZOLO Champion"
          options={adminUserDropdown}
          onChange={onInputChange}
        />
      </Flex>
      <Flex
        p="12px"
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gap="6px"
      >
        {json.map((field, index) => (
          <>
            {!field.name.startsWith("zolo_custom_") ? (
              <FieldComponent
                key={field.name}
                field={field}
                onInputChange={onInputChange}
                defaultValue={formState ? formState[field.name] : undefined}
              />
            ) : (
              // custom field
              <FieldComponent
                key={field.name}
                field={field}
                onInputChange={onInputChange}
                defaultValue={
                  formState?.customFieldsJson
                    ? formState.customFieldsJson[field.name]
                    : undefined
                }
              />
            )}
          </>
        ))}
      </Flex>
      {children}
    </>
  );
};

const FieldComponent = (props: {
  field: CustomFieldModel;
  onInputChange: (e: any) => void;
  defaultValue?: any;
}) => {
  const { field, onInputChange, defaultValue } = props;

  switch (field.type) {
    case CustomFieldType.TEXT:
    case CustomFieldType.NUMBER:
      return (
        <FormInput
          name={field.name}
          required={field.required}
          title={field.label}
          type={field.type === CustomFieldType.NUMBER ? "number" : undefined}
          defaultValue={defaultValue}
          value={defaultValue}
          onChange={onInputChange}
        />
      );
    case CustomFieldType.DATE:
      return (
        <DatepickerInput
          name={field.name}
          required={field.required}
          title={field.label}
          initialDate={undefined}
          onChange={onInputChange}
        />
      );
    case CustomFieldType.DROPDOWN:
      return (
        <DropdownInput
          name={field.name}
          required={field.required}
          title={field.label}
          options={transformArrayToObject(field.options)}
          defaultValue={convertToEnumKey(defaultValue ?? "")}
          onChange={onInputChange}
        />
      );
    case CustomFieldType.TEXTAREA:
      return (
        <TextAreaInput
          name={field.name}
          required={field.required}
          title={field.label}
          defaultValue={defaultValue}
          onChange={onInputChange}
        />
      );
    default:
      return null;
  }
};
