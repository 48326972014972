import { BaseService } from "../base.service";

export class BlanccoService extends BaseService {
  constructor() {
    super();
  }

  async getReportPdf(blanccoReportId: string) {
    const resp = await this.client.post(
      "/super/integration/blancco/report/download",
      {
        blanccoReportId,
      }
    );

    return resp?.data;
  }

  async syncReports(filter: { gt: string | Date; lte: string | Date }) {
    const resp = await this.client.post("/super/integration/blancco/sync", {
      filter,
    });

    return resp?.data;
  }

  async getLastSync() {
    const resp = await this.client.get("/super/integration/blancco/sync/last");
    return resp?.data;
  }
}

export const blanccoService = new BlanccoService();
