import { Box, Flex, Tbody, Td, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AvatarCustom } from "../../../form/avatar.custom";
import { TableContainer } from "../../../shared/table/TableContainer";

export const CompanyDetailBranchTable = ({ companies }) => {
  const navigate = useNavigate();

  const columns = [
    {
      header: "Company",
      accessor: "name",
      width: "300px",
      callback: (companyName: string, photoUri?: string) => (
        <Flex flexDir="row" gap="16px" alignItems="center">
          <AvatarCustom
            border="2px solid gray"
            src={photoUri}
            widthxheight="46px"
          />
          <Box fontWeight="600">{companyName}</Box>
        </Flex>
      ),
    },
    {
      header: "Job",
      accessor: "totalJobs",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "User",
      accessor: "totalUsers",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Inventory",
      accessor: "totalInventory",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Quote (total)",
      accessor: "totalQuotePrice",
      callback: (price: number) => <>{price ? <Box>${price}</Box> : ""}</>,
    },
    {
      header: "Sales (total)",
      accessor: "totalSalePrice",
      callback: (price: number) => (
        <>{price ? <Box fontWeight="bold">${price}</Box> : ""}</>
      ),
    },
    {
      disableSort: true,
      header: "",
      accessor: "deleted",
      callback: (deleted: any) => <>{deleted ? "DELETED" : ""}</>,
    },
  ];

  return (
    <>
      <TableContainer columns={columns}>
        <Tbody>
          {companies.map((row, i) => (
            <Tr
              _hover={{
                cursor: "pointer",
                backgroundColor: "lightGray",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/app/company/${row.id}`);
              }}
              key={i}
            >
              {columns.map((column, j) => (
                <Td key={j} height="60px">
                  {column.callback ? (
                    <>{column.callback(row[column.accessor], row.photoUri)}</>
                  ) : (
                    <>{row[column.accessor]}</>
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </TableContainer>
    </>
  );
};
