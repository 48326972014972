import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { Modal } from "../Modal";
import { QrCustom } from "../../shared/QrCustom";
import { ButtonCustom } from "../../form/controls/form.button";
import { DownloadIcon } from "@chakra-ui/icons";
import { onBulkQrPrint } from "../../../util/qr.util";

export const BulkQrMoldal = (props: Props) => {
  const { isOpen, onClose, onSubmit, inventory } = props;

  const inventoryService = new InventoryService();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    onBulkQrPrint();
    // onSubmit();
  };

  return (
    <Modal isOpen={isOpen} header="" onClose={handleClose} width="600px">
      {/* chakra ui grid that is 3 by 5 */}
      <Box id="BulkQRCode" h="100%">
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          {inventory.map((item, i) => (
            <GridItem key={i} p="6px" pb="26px" border="1px dashed lightGray">
              <QrCustom showIcons={false} id={item.id} number={item.qrNo} />
            </GridItem>
          ))}
          <GridItem>
            <ButtonCustom style={{ width: "100%" }} onClick={handleSubmit}>
              Download <DownloadIcon ml="6px" />
            </ButtonCustom>
          </GridItem>
        </Grid>
      </Box>
    </Modal>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  inventory: { id: string; qrNo: number }[];
}
