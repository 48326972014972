import { Flex, ModalFooter, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { InventoryModel } from "../../../models/inventory.model";
import { InventoryCustomForm } from "../../shared/custom-forms/InventoryCustomForm";

export const EditInventoryModal = ({
  isOpen,
  onClose,
  onSubmit,
  inventoryId,
  formJson,
}) => {
  const inventoryService = new InventoryService();
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState({} as InventoryModel);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const obj = { ...inventory, ...formState, id: inventoryId };
    delete obj.copyCount;

    await inventoryService
      .update(obj)
      .then(() => {
        toast(successToast("Inventory updated."));
        handleClose();
        onSubmit();
      })
      .catch(() => {
        toast(errorToast("Error updating inventory."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({});
    setInventory({} as InventoryModel);
    onClose();
  };

  const init = async () => {
    const inventory = await inventoryService.get(inventoryId);
    setInventory(inventory);
    setFormState({ ...inventory, ...inventory.customFieldsJson });
  };

  useEffect(() => {
    setInventory({} as InventoryModel);
    if (inventoryId) {
      init();
    }
    return () => {};
  }, [inventoryId]);

  return (
    <Modal
      width="800px"
      isOpen={isOpen}
      header="Edit inventory item"
      onClose={handleClose}
    >
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <InventoryCustomForm
          initialState={formState}
          json={formJson?.filter((x) => x.name !== "copyCount")}
          onInputChange={onInputChange}
        >
          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </InventoryCustomForm>
      </form>
    </Modal>
  );
};
