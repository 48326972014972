import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Table as ChakraTable,
  useToast,
} from "@chakra-ui/react";
import { ButtonCustom } from "../../../components/form/controls/form.button";
import { AddJobModal } from "../../../components/app/job/add-job.modal";
import { JobService } from "../../../service/job/job.service";
import { convertIsoTime } from "../../../util/date.util";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../../components/shared/PageTitle";
import { successToast } from "../../../constants/toast.constants";
import { ToggleInput } from "../../../components/form/controls/toggle.input";
import { JobStatus } from "../../../models/enum/job-status.enum";
import { StatusPickerInput } from "../../../components/form/controls/status-picker.input";
import AddIcon from "@mui/icons-material/Add";
import PopoverCustom from "../../../components/shared/PopoverCustom";
import { TableContainer } from "../../../components/shared/table/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { update } from "../../../store/slice/Account/job.slice";
import { sortDirection } from "../../../util/list.util";
import { Loading } from "../../../components/shared/Loading";

interface Column {
  header: string;
  width?: string;
  accessor: string;
  callback?: (arg0?: any, arg1?: any) => React.ReactNode;
}

export const Job = () => {
  const jobService = new JobService();

  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [jobs, setJobs] = useState([] as any[]);
  const [deleteJob, setDeleteJob] = useState({ id: "", isOpen: false });

  const [toggled, setToggled] = useState(false);
  const { sortColumn } = useSelector((state: RootState) => state.job);

  const handleStatusUpdate = async (id: string, status: string) => {
    await jobService.updateStatus({ id, status }).then(() => {
      let data = [...jobs].map((job) => {
        if (job.id === id) {
          return { ...job, status };
        }
        return job;
      });

      if (sortColumn?.accessor?.length)
        data = sortDirection(data, sortColumn.accessor, sortColumn.direction);

      setJobs(data);
    });
  };

  const columns: Column[] = [
    {
      header: "Ref #",
      width: "75px",
      accessor: "jobNo",
      callback: (jobNo: string, job: any) => (
        <Text
          fontWeight="600"
          color="brand.green"
          cursor="pointer"
          onClick={() => navigate(`/app/job/${job.id}`)}
        >{`${jobNo}`}</Text>
      ),
    },
    {
      header: "Company name",
      accessor: "companyName",
      width: "200px",
      callback: (companyName: string, job: any) => (
        <Text
          fontWeight="600"
          color="brand.green"
          cursor="pointer"
          onClick={() => navigate(`/app/company/${job.companyId}`)}
        >{`${companyName}`}</Text>
      ),
    },
    {
      header: "Contact",
      width: "200px",
      accessor: "contactEmail",
    },
    {
      header: "No. items",
      width: "125px",
      accessor: "totalInventory",
      callback: (totalInventory: string) => (
        <>{+totalInventory === 0 ? "-" : totalInventory}</>
      ),
    },
    {
      header: "Start date",
      width: "125px",
      accessor: "startDate",
    },
    {
      header: "Total quote",
      width: "150px",
      accessor: "totalQuotePrice",
      callback: (price: number) => <>{price ? <Box>${price}</Box> : ""}</>,
    },
    {
      header: "Total price",
      width: "150px",
      accessor: "totalSalePrice",
      callback: (price: number) => (
        <>{price ? <Box fontWeight="bold">${price}</Box> : ""}</>
      ),
    },
    {
      header: "Status",
      width: "125px",
      accessor: "status",
      callback: (status: string, job: any) => (
        <StatusPickerInput
          key={job.id}
          id={job.id}
          status={status}
          options={JobStatus}
          onClick={handleStatusUpdate}
        />
      ),
    },
    {
      header: "Notes",
      width: "300px",
      accessor: "notes",
      callback: (notes) =>
        notes?.length && (
          <PopoverCustom
            width="300px"
            trigger={
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                px={6}
                _hover={{
                  bgColor: "gray.200",
                }}
                cursor="pointer"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {notes}
                </Text>
              </Box>
            }
          >
            <Text whiteSpace="normal">{notes}</Text>
          </PopoverCustom>
        ),
    },
  ];

  const handleSort = (props: { accessor: string; direction: 0 | 1 }) => {
    dispatch(
      update({
        sortColumn: { accessor: props.accessor, direction: props.direction },
      })
    );

    setJobs(sortDirection(jobs, props.accessor, props.direction));
  };

  const init = async () => {
    setLoading(true);
    const filter = {
      incompleteOnly: !toggled,
      status: toggled ? "COMPLETE" : undefined,
    };

    await jobService
      .getList(filter)
      .then((jobs) => {
        let data = jobs.map((job) => {
          return {
            ...job,
            startDate: convertIsoTime(job.startDate),
          };
        });

        if (sortColumn?.accessor?.length)
          data = sortDirection(data, sortColumn.accessor, sortColumn.direction);

        setJobs(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = async () => {
    await jobService.delete(deleteJob.id).then(() => {
      setDeleteJob({ id: "", isOpen: false });
      toast(successToast("Job deleted"));
    });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [toggled]);

  return (
    <>
      <Loading loading={loading} />
      {/* this is just a placeholder - change to Chakra table or similar */}
      <Flex flexDirection="column">
        <Flex flexDirection="row" justifyContent="flex-end">
          <ButtonCustom onClick={() => setModalOpen(true)}>
            <AddIcon />
            Add job
          </ButtonCustom>
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        <Flex my="13px" flexDirection="row" justifyContent="flex-end">
          <ToggleInput
            style={{ justifyContent: "flex-end" }}
            leftLabel="Progress"
            rightLabel="Complete"
            onChange={(e) => {
              setToggled(e.target.checked);
            }}
          />
        </Flex>
        <TableContainer
          columns={columns}
          onSort={handleSort}
          sortColumn={sortColumn}
        >
          <Tbody zIndex={9}>
            {jobs.map((row, i) => (
              <Tr key={i} height="100%">
                {columns.map((column, j) => (
                  <Td
                    key={j}
                    height="40px"
                    width={column.width ?? "full"}
                    p={column.accessor === "notes" && 0}
                  >
                    {!column.callback
                      ? row[column.accessor]
                      : column.callback(row[column.accessor], row)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </TableContainer>
      </Flex>
      <AddJobModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={() => {
          init();
        }}
      />
    </>
  );
};
