import { Flex, ModalFooter, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useState } from "react";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { InventoryCustomForm } from "../../shared/custom-forms/InventoryCustomForm";

export const BulkEditInventoryModal = ({
  isOpen,
  onClose,
  onSubmit,
  inventoryIds,
  formJson,
}) => {
  const inventoryService = new InventoryService();
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    // harsh delete required as we do not want to override any existing values by accident
    if (value === "") {
      delete formState[name];
      return;
    }

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const keys = Object.keys(formState);
    if (!keys.length) {
      toast(errorToast("Please enter at least one field to update."));
      return;
    }

    setLoading(true);

    await inventoryService
      .bulkUpdate(inventoryIds, formState)
      .then(() => {
        toast(successToast("Update successful."));
        handleClose();
        onSubmit();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({});
    onClose();
  };

  return (
    <Modal
      width="800px"
      isOpen={isOpen}
      header="Bulk edit inventory items"
      onClose={handleClose}
    >
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <InventoryCustomForm
          json={formJson?.filter((x) => x.name !== "copyCount")}
          onInputChange={onInputChange}
        >
          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </InventoryCustomForm>
      </form>
    </Modal>
  );
};
