import { EntityType } from "../../models/enum/entity-type.enum";
import { CustomFormModel } from "../../models/form-builder/custom-form.model";
import { BaseService } from "../base.service";

export class FormBuilderService extends BaseService {
  constructor() {
    super();
  }

  async get(id: string): Promise<CustomFormModel> {
    const resp = await this.client.get(`/super/form/builder?id=${id}`);
    return resp.data;
  }

  async post(form: CustomFormModel): Promise<number> {
    const resp = await this.client.post(`/super/form/builder`, form);
    return resp.data;
  }

  async put(form: CustomFormModel): Promise<number> {
    const resp = await this.client.put(`/super/form/builder`, form);
    return resp.data;
  }

  async delete(id: string): Promise<number> {
    const resp = await this.client.post(`/super/form/builder/delete`, { id });
    return resp.data;
  }

  async getList(): Promise<CustomFormModel[]> {
    const resp = await this.client.get(`/super/form/builder/list`);
    return resp.data;
  }

  async updateFormJson(data: { id: string; formJson: string }) {
    const resp = await this.client.put(`/super/form/builder/json`, data);
    return resp.data;
  }

  async activate(data: { id: string; entityType: EntityType }) {
    const resp = await this.client.put(`/super/form/builder/activate`, data);
    return resp.data;
  }

  async deactivate(data: { id: string; entityType: EntityType }) {
    const resp = await this.client.put(`/super/form/builder/deactivate`, data);
    return resp.data;
  }

  async duplicate(id: string) {
    const resp = await this.client.post(`/super/form/builder/duplicate`, {
      id,
    });

    return resp.data;
  }
}
