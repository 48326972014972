import { Flex, ModalFooter, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { TextAreaInput } from "../../form/controls/text-area.input";
import { DropdownInput } from "../../form/controls/dropdown.input";
import { DropoffSource } from "../../../models/enum/dropoff.enum";
import { useEffect, useState } from "react";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { FileUploadInput } from "../../form/controls/file-upload.input";
import { ellipsesText } from "../../../util/string.util";
import { DropoffService } from "../../../service/company/dropoff.service";
import { DropoffModel } from "../../../models/dropoff.model";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";

export const DropoffModal = ({ isOpen, onClose, onSubmit }) => {
  const dropoffService = new DropoffService();
  const toast = useToast();

  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadState, setUploadState] = useState({
    name: "",
    formData: {},
    uploaded: false,
  });
  const [csvData, setCsvData] = useState(null as any);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onFileUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file[0]);

    setCsvData(formData);
    setUploadState({
      name: file[0].name,
      formData: formData,
      uploaded: true,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (!csvData) {
      toast(errorToast("Please upload a file."));
      return;
    }

    const data = csvData;
    data.append("bodyData", JSON.stringify(formState));

    await dropoffService
      .post(data)
      .then(() => {
        onSubmit();
        onClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({});
    setUploadState({
      name: "",
      formData: {},
      uploaded: false,
    });
    setCsvData(null);
    onClose();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal isOpen={isOpen} header="Request a dropoff" onClose={handleClose}>
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <FileUploadInput
            uploadedLabel={ellipsesText(uploadState.name, 16)}
            type="document"
            onDrop={onFileUpload}
            accept={{
              "text/csv": [".csv"],
              "application/vnd.ms-excel": [".xls"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            }}
            maxFiles={1}
            isUploaded={uploadState.uploaded}
            additionalText="We only accept .csv, .xlsx, .xls"
            invalidInputMessage="Please take a photo or upload one."
          />
          <DatepickerInput
            initialDate={new Date()}
            title="Preferred pickup date"
            name="requestDate"
            onChange={onInputChange}
          />
          <DropdownInput
            title="Data source"
            name="source"
            options={DropoffSource}
            onChange={onInputChange}
            required
          />
          <TextAreaInput
            title="Additional info"
            name="notes"
            onChange={onInputChange}
          />

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Confirm</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
    </Modal>
  );
};
