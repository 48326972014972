export const ellipsesText = (str: string, limit: number) => {
  if (!str) return "";

  return str.length > limit ? str.substring(0, limit - 3) + "..." : str;
};

export const convertToEnumKey = (str: string) => {
  if (!str?.length) return null;

  return str.toUpperCase().replace(/ /g, "_");
};
