import { Box, Flex, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Modal } from "../app/Modal";
import { Loading } from "../shared/Loading";
import { FormInput } from "../form/controls/form.input";
import { errorToast, successToast } from "../../constants/toast.constants";
import { companyService } from "../../service/company/company.service";
import { CountryDropdownInput } from "../form/controls/country-dropdown.input";
import { ButtonCustom } from "../form/controls/form.button";

export const CompanyDeleteModal = ({ isOpen, onClose, onSubmit, company }) => {
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });

    setIsValid(value === company.name);
  };

  const handleClose = () => {
    setFormState({});
    setIsValid(false);
    onClose();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isValid) {
      toast(errorToast("Company name does not match"));
      return;
    }

    setLoading(true);

    await companyService
      .deactivateCompay(company.id)
      .then(() => {
        toast(successToast("Company updated"));
        onSubmit();
      })
      .catch((err) => {
        console.log(err)
        toast(errorToast(err.response.data.message));
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal isOpen={isOpen} header="This action is irreversible" onClose={handleClose}>
      <Box mb="12px">This will permanently delete <span style={{ fontWeight: 600 }}>{company.name}</span>. <br/> Please type in the company name exactly as you see it to initiate the deletion. <br/> </Box>

      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <FormInput
            required
            title="Enter company name"
            name="name"
            onChange={onInputChange}
          />

          <Flex gap="12px" justifyContent="flex-end">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
            <ButtonCustom danger type="submit">Delete Now</ButtonCustom>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
