import { useState, useEffect } from "react";
import { UserService } from "../../../../service/user/user.service";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IconCustom } from "../../../shared/IconCustom";
import { CardCustom } from "../../../shared/CardCustom";
import { CardTitle } from "../../../shared/CardTitle";
import {
  errorToast,
  successToast,
} from "../../../../constants/toast.constants";
import AlertDialog from "../../../shared/AlertDialog";
import { useAppSelector } from "../../../../util/hooks.util";
import { ButtonCustom } from "../../../form/controls/form.button";
import { InviteUserModal } from "../inviteUser.modal";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import { Role } from "../../../../models/enum/role.enum";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const AccountUserCard = ({}) => {
  const userService = new UserService();
  const currentUser = useAppSelector((state) => state.userProfile);

  const [users, setUsers] = useState([]);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [userToDeactivate, setUserToDeactivate] = useState("");
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();

  const toast = useToast();
  const getUserRole = (isManager: boolean) =>
    isManager ? Role.MANAGER : Role.STANDARD;

  const init = async () => {
    const users = await userService.getUsers();
    setUsers(users);
  };

  useEffect(() => {
    init();
  }, []);

  const handleDeactivate = async (email: string) => {
    await userService
      .deactivate({ email })
      .then(() => {
        alertOnClose();
        init();
        toast(successToast(`${email} has been deactivated.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      });
  };

  const handleActivate = async (email: string) => {
    await userService
      .activate({ email })
      .then(() => {
        init();
        toast(successToast(`${email} has been activated.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      });
  };

  const handleRoleSelect = async (role: string, email: string) => {
    const isManager = role === "MANAGER";
    await userService
      .updateRole({ isManager, email })
      .then(() => {
        init();
        toast(successToast(`${email} has been updated to ${Role[role]}.`));
      })
      .catch((err) => {
        console.log(err);
        toast(errorToast(`Something went wrong. Please try again.`));
      });
  };

  const columns = [
    {
      header: "Email",
      accessor: "email",
      callback: (email, user) => (
        <Flex
          color={user.deleted ? "#A0AEBF" : undefined}
          flexDir="row"
          gap="4px"
        >
          {user.deleted ? (
            <CancelIcon style={{ fontSize: "28px", color: "#A0AEBF" }} />
          ) : (
            <IconCustom fileName="check" />
          )}

          {email}
        </Flex>
      ),
    },
    {
      header: "isManager",
      accessor: "isManager",
      callback: (role, user) => {
        return currentUser.isManager && user.email !== currentUser.email ? (
          <Flex color={user.deleted ? "#A0AEBF" : undefined}>
            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                rightIcon={<ChevronDownIcon />}
                fontWeight="normal"
                p={1}
                height="inherit"
              >
                {getUserRole(role)}
              </MenuButton>
              <MenuList>
                {(Object.keys(Role) as Array<Role>).map((key, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      getUserRole(role) !== Role[key] &&
                        handleRoleSelect(key, user.email);
                    }}
                  >
                    {Role[key]}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <Text p={1}>{getUserRole(role)}</Text>
        );
      },
    },
    currentUser.isManager && {
      accessor: "deleted",
      callback: (deleted, user) => {
        if (user.email === currentUser.email) {
          return;
        }
        return (
          <>
            {deleted ? (
              <IconButton
                variant="ghost"
                aria-label="Email"
                size="sm"
                color="#A0AEBF"
                icon={<EmailIcon fontSize="small" />}
                onClick={() => handleActivate(user.email)}
              />
            ) : (
              <IconButton
                variant="ghost"
                aria-label="Delete"
                size="sm"
                color="red.500"
                icon={<DeleteForeverIcon fontSize="small" />}
                onClick={() => {
                  setUserToDeactivate(user.email);
                  alertOnOpen();
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <CardCustom gap="6px">
        <Flex flexDir="row" justifyContent="space-between">
          <CardTitle>User management ({users?.length ?? 0})</CardTitle>
          <ButtonCustom
            hidden={!currentUser.isManager}
            onClick={() => setInviteOpen(true)}
            neutral
            style={{
              width: "120px",
              height: "30px",
              backgroundColor: "transparent",
            }}
          >
            <AddIcon />
            Invite user
          </ButtonCustom>
        </Flex>
        <TableContainer mt="24px">
          <Table variant="simple">
            <Tbody>
              {users.map((row, i) => (
                <Tr key={i}>
                  {columns.map((column, j) => (
                    <Td key={j} py={1} pl={j === 0 && 0} borderBottom="none">
                      {column.callback ? (
                        <>{column.callback(row[column.accessor], row)}</>
                      ) : (
                        <>{row[column.accessor]}</>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardCustom>
      <InviteUserModal
        isOpen={inviteOpen}
        onClose={() => setInviteOpen(false)}
        onSubmit={() => {}}
      />
      <AlertDialog
        isOpen={alertIsOpen}
        onClose={alertOnClose}
        title={`Deactivate ${userToDeactivate}`}
        colorScheme="red"
        confirmText="Deactivate"
        onConfirm={() => handleDeactivate(userToDeactivate)}
      >
        Are you sure? You can't undo this action.
      </AlertDialog>
    </>
  );
};
