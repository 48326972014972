// dataUrl => "data:image/png;base64,......"
export const dataURLtoFile = (dataurl: string, filename: string) => {
  var arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/);
  if (!mime) return;

  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename);
};

export const previewImageBlob = (blob: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const byteArrToBase64 = (arr: any) => {
  arr = new Uint8Array(arr);
  return btoa(
    arr.reduce((data: any, byte: any) => data + String.fromCharCode(byte), "")
  );
};

export const downloadCsvToMachine = (csv: string, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([csv], { type: "text/csv" }));

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
