import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const ProviderWrapper = ({ children }) => {
  const email = useSelector((state: RootState) => state.userProfile.email);

  // Usage: color="brand.green" in child components
  const theme = extendTheme({
    colors: {
      brand: {
        darkGreen: "#24362F",
        green: "#285541",
        lightGreen: "#447662",
        white: "#FBFBF8",
        yellow: "#ffdf6d",
        brown: "#C1834F",
        gray: "#929292",
        red: "#FF6C6C",
      },
    },
  });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
