import { Box, Flex, ModalFooter, Text, useToast } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { useEffect, useState } from "react";
import { FileUploadInput } from "../../form/controls/file-upload.input";
import { ellipsesText } from "../../../util/string.util";
import { errorToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { TooltipCustom } from "../../form/controls/form.tooltip";

export const UploadInventoryModal = ({
  isOpen,
  onClose,
  onSubmit,
  jobId = undefined,
  isBulkEdit = false,
}) => {
  const inventoryService = new InventoryService();

  const toast = useToast();

  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadState, setUploadState] = useState({
    name: "",
    formData: {},
    uploaded: false,
  });

  const [csvData, setCsvData] = useState(null as any);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [uploadErrors, setUploadErrors] = useState(
    [] as { row: number; message: string; validValues: [] }[]
  );

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onFileUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file[0]);

    setCsvData(formData);
    setUploadState({
      name: file[0].name,
      formData: formData,
      uploaded: true,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!csvData) {
      toast(errorToast("Please upload a file."));
      return;
    }

    setLoading(true);
    const data = csvData;

    await inventoryService
      .uploadInventoryCsv(jobId, isBulkEdit, csvData)
      .then(() => {
        onSubmit();
        onClose();
      })
      .catch((resp) => {
        console.log(resp.response.data.errors);
        setUploadErrors(resp.response.data.errors);
        setErrorModalOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({});
    setUploadState({
      name: "",
      formData: {},
      uploaded: false,
    });
    setCsvData(null);
    onClose();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      header={isBulkEdit ? "Upload & Update Items" : "Upload inventory"}
      onClose={handleClose}
    >
      <Loading loading={loading} fill ignoreDelay />
      <Box hidden={!isBulkEdit}>
        Recommended approach is to first EXPORT the Inventory items you want to
        change using the 'Export' button. Once you export, only update the
        fields you are interested in updating. Please DO NOT remove the qrNo
        column, as this will cause errors.
      </Box>
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <FileUploadInput
            uploadedLabel={ellipsesText(uploadState.name, 16)}
            type="document"
            onDrop={onFileUpload}
            accept={{
              "text/csv": [".csv"],
            }}
            maxFiles={1}
            isUploaded={uploadState.uploaded}
            additionalText="We only accept .csv"
          />

          <ModalFooter>
            <Flex gap="12px">
              <ButtonCustom secondary neutral onClick={onClose}>
                Cancel
              </ButtonCustom>
              <ButtonCustom type="submit">Process</ButtonCustom>
            </Flex>
          </ModalFooter>
        </Flex>
      </form>
      <ErrorModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        errors={uploadErrors}
      />
    </Modal>
  );
};

const ErrorModal = ({ isOpen, onClose, errors }) => {
  return (
    <Modal
      isOpen={isOpen}
      header={<Text color="brand.red">Fatal errors</Text>}
      onClose={onClose}
    >
      <Flex flexDir="column" gap="12px">
        <Text fontWeight="bold" textAlign="center">
          There are some fatal errors you must correct before continuing.
        </Text>
        <Text fontWeight="bold" textAlign="center">
          Hint: Export an inventory list and use it as a template - being sure
          to remove any invalid columns. All values are case-sensitive.
        </Text>

        <Flex flexDir="column" gap="16px">
          {errors.map((error, index) => (
            <Box>
              <Text fontWeight="bold" key={index}>
                Row {+error.row === 0 ? "header" : error.row}
              </Text>
              <Text>{error.message}</Text>
              <Flex flexDir="row" gap="6px">
                <Text>Accepted values</Text>
                <TooltipCustom label={error.validValues?.join(", ")} />
              </Flex>
            </Box>
          ))}
        </Flex>
        <ButtonCustom onClick={onClose}>Close</ButtonCustom>
      </Flex>
    </Modal>
  );
};
