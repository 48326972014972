import { Box, Flex, Heading } from "@chakra-ui/react";

export const CardTitle = ({ children }) => {
  return (
    <Flex flexDir="column" gap="4px">
      <Heading size="md" color="brand.green">
        {children}
      </Heading>
      <Box bg="brand.yellow" h="3px" w="40px"></Box>
    </Flex>
  );
};
