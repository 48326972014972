import { JobStatus } from "../../models/enum/job-status.enum";
import { JobModel } from "../../models/job.model";
import { JobMediaContext } from "../../models/media/media-context.enum";
import { BaseService } from "../base.service";

export class JobService extends BaseService {
  constructor() {
    super();
  }

  async get(id: string): Promise<JobModel[]> {
    const resp = await this.client.get(`/super/job?id=${id}`);
    return resp.data;
  }

  async getList(filter?: {
    companyId?: string;
    staatus?: JobStatus;
    incompleteOnly?: boolean;
  }): Promise<JobModel[]> {
    const resp = await this.client.post(`/super/job/list`, { filter });
    return resp.data;
  }

  async post(data: any): Promise<any> {
    const resp = await this.client.post(`/super/job`, data);
    return resp.data;
  }

  async update(data: any): Promise<any> {
    const resp = await this.client.put(`/super/job`, data);
    return resp.data;
  }

  async updateStatus(data: any) {
    return await this.client.put(`/super/job/status`, data);
  }

  async delete(id: string) {
    const resp = await this.client.put(`/super/job/delete`, { id });
    return resp.data;
  }

  async uploadAttachment(jobId: string, type: JobMediaContext, data: any) {
    let url = `/super/job/media/upload?jobId=${jobId}&type=${type}`;

    const resp = await this.client.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return resp?.data;
  }

  async deleteAttachment(mediaId: string, fileName: string) {
    await this.client.post(`/super/job/media/delete`, { mediaId, fileName });
  }
}

export const jobService = new JobService();
