import { Box, Text } from "@chakra-ui/react";

export const FormTitle = ({
  title,
  required,
}: {
  title: string;
  required?: boolean;
}) => {
  return (
    <Box fontWeight={600} fontSize={"14px"}>
      {title}
      {required ? (
        <Box
          as={"span"}
          fontWeight="700"
          fontSize="14px"
          color="#792551"
          ml="3px"
        >
          *
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
