import { useEffect, useState } from "react";
import { CompanyService } from "../../../service/company/company.service";
import { Box, Flex, Tbody, Td, Tr } from "@chakra-ui/react";
import { ButtonCustom } from "../../../components/form/controls/form.button";
import { downloadCsvToMachine } from "../../../util/file.util";
import { useNavigate } from "react-router-dom";
import { DownloadIcon } from "@chakra-ui/icons";
import { TableContainer } from "../../../components/shared/table/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  update,
  updatePageSize,
} from "../../../store/slice/Account/company.slice";
import { AvatarCustom } from "../../../components/form/avatar.custom";
import { ToggleInput } from "../../../components/form/controls/toggle.input";
import { Loading } from "../../../components/shared/Loading";
import { TableFooter } from "../../../components/shared/table/TableFooter";
import { FormInput } from "../../../components/form/controls/form.input";

const PAGE_SIZE = 1;

export const Company = ({}) => {
  const companyService = new CompanyService();

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState({
    data: [],
    totalItems: 0,
    totalPage: 0,
  } as { data: any[]; totalPage: number; totalItems: number });

  const [filter, setFilter] = useState({ isHeadquarters: true });
  const [pageNum, setPageNum] = useState(1);

  const [companyName, setCompanyName] = useState(undefined);
  const [debouncedCompanyName, setDebouncedCompanyName] = useState(companyName);
  const [companyNameSearch, setCompanyNameSearch] = useState(undefined);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sortColumn, pageSize } = useSelector(
    (state: RootState) => state.company
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCompanyName(companyName);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [companyName]);

  const handleInputChange = (e) => {
    setCompanyName(e.target.value);
  };

  useEffect(() => {
    setCompanyNameSearch(debouncedCompanyName);
  }, [debouncedCompanyName]);

  const handleSort = (props: { accessor: string; direction: 0 | 1 }) => {
    dispatch(
      update({
        sortColumn: { accessor: props.accessor, direction: props.direction },
      })
    );

    setPageNum(1);
    init(filter, 1, props);

    return;
  };

  const columns = [
    {
      header: "Company",
      accessor: "name",
      width: "300px",
      callback: (companyName: string, photoUri?: string) => (
        <Flex flexDir="row" gap="16px" alignItems="center">
          <AvatarCustom
            border="2px solid gray"
            src={photoUri}
            widthxheight="46px"
          />
          <Box fontWeight="600">{companyName}</Box>
        </Flex>
      ),
    },
    {
      header: "Job",
      accessor: "totalJobs",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "User",
      accessor: "totalUsers",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Inventory",
      accessor: "totalInventory",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Quote (total)",
      accessor: "totalQuotePrice",
      callback: (price: number) => <>{price ? <Box>${price}</Box> : ""}</>,
    },
    {
      header: "Sales (total)",
      accessor: "totalSalePrice",
      callback: (price: number) => (
        <>{price ? <Box fontWeight="bold">${price}</Box> : ""}</>
      ),
    },
    {
      header: "Country",
      accessor: "country",
      callback: (country: any) => <>{country}</>,
    },
    {
      disableSort: true,
      header: "",
      accessor: "deleted",
      callback: (deleted: any) => <>{deleted ? "DELETED" : ""}</>,
    },
  ];

  const handleExport = async () => {
    await companyService.exportToCsv().then((csvData) => {
      downloadCsvToMachine(csvData, "companies-export.csv");
    });
  };

  const init = async (filter?: any, page?: number, sort?: any) => {
    setLoading(true);
    await companyService
      .getList(
        { ...filter, name: companyNameSearch },
        page,
        pageSize,
        sort ?? sortColumn
      )
      .then((resp) => {
        setCompanies(resp);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    init(filter, 1, sortColumn);
  }, [pageSize]);

  useEffect(() => {
    if (companyNameSearch !== undefined && companyNameSearch !== null) {
      init(filter, 1, sortColumn);
    }
  }, [companyNameSearch]);

  return (
    <>
      <Loading loading={loading} fill />
      <Flex
        flexDirection="row"
        alignItems="center"
        gap="6px"
        justifyContent="space-between"
        mb="16px"
      >
        <Box w="100%" mr="20px">
          <FormInput
            name=""
            title=""
            placeholder="Search by company name..."
            onChange={handleInputChange}
          />
        </Box>
        <Flex flexDir="row" gap="6px" alignItems="center">
          <ToggleInput
            leftLabel="HQ"
            rightLabel="BRANCH"
            onChange={(e) => {
              setFilter({ isHeadquarters: !e.target.value });
              setPageNum(1);
              init({ isHeadquarters: !e.target.value }, 1);
            }}
          />
          <ButtonCustom
            disabled={!companies?.data?.length}
            onClick={handleExport}
          >
            Export to csv
            <DownloadIcon ml="6px" />
          </ButtonCustom>
        </Flex>
      </Flex>
      <TableContainer
        columns={columns}
        onSort={handleSort}
        sortColumn={sortColumn}
      >
        <Tbody>
          {companies?.data?.map((row, i) => (
            <Tr
              _hover={{
                cursor: "pointer",
                backgroundColor: "lightGray",
              }}
              onClick={() => {
                navigate(`/app/company/${row.id}`);
              }}
              key={i}
            >
              {columns.map((column, j) => (
                <Td key={j} height="60px">
                  {column.callback ? (
                    <>{column.callback(row[column.accessor], row.photoUri)}</>
                  ) : (
                    <>{row[column.accessor]}</>
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </TableContainer>
      <TableFooter
        count={companies.data?.length}
        totalItems={companies.totalItems}
        totalPage={companies.totalPage}
        pageNum={pageNum}
        onChange={(num) => {
          setPageNum(num);
          init(filter, num);
        }}
        pageSize={pageSize}
        onPageSizeChange={(size) => {
          setPageNum(1);
          dispatch(updatePageSize({ pageSize: size }));
          init(filter, pageNum);
        }}
      />
    </>
  );
};
