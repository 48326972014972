import { useEffect, useState } from "react";
import { DropoffService } from "../../service/company/dropoff.service";
import { Box, Flex, IconButton, Text, Tbody, Td, Tr } from "@chakra-ui/react";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { DropoffModal } from "../../components/app/dropoff/dropoff.modal";
import { DropoffStatus } from "../../models/enum/dropoff.enum";
import { convertIsoTime } from "../../util/date.util";
import { downloadCsvToMachine } from "../../util/file.util";
import { DownloadIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { StatusPickerInput } from "../../components/form/controls/status-picker.input";
import { ToggleInput } from "../../components/form/controls/toggle.input";
import PopoverCustom from "../../components/shared/PopoverCustom";
import { TableContainer } from "../../components/shared/table/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../store/slice/Account/dropoff.slice";
import { sortDirection } from "../../util/list.util";
import { RootState } from "../../store";

const naRender = (value) => value ?? "-";
interface Column {
  header: string;
  width?: string;
  accessor: string;
  callback?: (arg0?: any, arg1?: any) => React.ReactNode;
}

interface Row {
  [key: string]: string | number;
}

export const Dropoff = ({}) => {
  const dropoffService = new DropoffService();
  const [dropoffs, setDropoffs] = useState([]);
  const [completedDropoffs, setCompletedDropoffs] = useState([]);

  const [toggled, setToggled] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sortColumn } = useSelector((state: RootState) => state.dropoff);

  const init = async () => {
    let dropoffs = await dropoffService.getList();

    if (!dropoffs?.length) return;

    if (sortColumn) {
      dropoffs = sortDirection(
        dropoffs,
        sortColumn.accessor,
        sortColumn.direction
      );
    }

    setDropoffs(dropoffs.filter((x) => x.status.toString() !== "PROCESSED"));
    setCompletedDropoffs(
      dropoffs.filter((x) => x.status.toString() === "PROCESSED")
    );
  };

  const handleStatusUpdate = async (id: string, status: string) => {
    await dropoffService.updateStatus({ id, status });
    await init();
  };

  const handleExport = async () => {
    await dropoffService.exportToCsv().then((csvData) => {
      downloadCsvToMachine(csvData, "inventory-export.csv");
    });
  };

  const handleDownload = async (id: string) => {
    const resp = await dropoffService.downloadFile({ id }).catch((err) => {
      console.log(err);
    });

    const fileData = resp.data.data;
    const blob = new Blob([new Uint8Array(fileData)], {
      type: resp.contentType,
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${resp.filename}`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const columns: Column[] = [
    {
      header: "Company",
      accessor: "companyName",
      width: "180px",
      callback: (name: string, dropoff: any) => (
        <Text
          fontWeight="700"
          cursor="pointer"
          color="brand.green"
          onClick={() => navigate(`/app/company/${dropoff.companyId}`)}
        >
          {name}
        </Text>
      ),
    },
    {
      header: "created",
      accessor: "createdDate",
      width: "125px",
      callback: (createdDate: Date) => <>{convertIsoTime(createdDate)}</>,
    },
    {
      header: "pick-up date",
      accessor: "requestDate",
      width: "125px",
      callback: (requestDate: Date) => <>{convertIsoTime(requestDate)}</>,
    },
    {
      header: "Pick-up address",
      accessor: "addressName",
      width: "300px",
      callback: (addressName) =>
        addressName?.length && (
          <PopoverCustom
            width="300px"
            trigger={
              <Box
                w="100%"
                display="flex"
                alignItems="center"
                height="100%"
                _hover={{
                  bgColor: "gray.200",
                }}
                cursor="pointer"
              >
                <Box
                  maxWidth="100%"
                  flex="1"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {addressName}
                </Box>
              </Box>
            }
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: addressName.replace(/\n/g, "<br />"),
              }}
            ></Box>
          </PopoverCustom>
        ),
    },
    {
      header: "Source",
      accessor: "source",
      width: "125px",
      callback: (source: string) => <>{naRender(source?.toUpperCase())}</>,
    },
    {
      header: "Status",
      accessor: "status",
      width: "125px",
      callback: (status: string, dropoff: any) => (
        <StatusPickerInput
          key={dropoff.id}
          id={dropoff.id}
          status={status}
          options={DropoffStatus}
          onClick={handleStatusUpdate}
        />
      ),
    },
    {
      header: "Notes",
      width: "100px",
      accessor: "notes",
      callback: (notes) =>
        notes?.length && (
          <PopoverCustom
            width="300px"
            trigger={
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                px={6}
                _hover={{
                  bgColor: "gray.200",
                }}
                cursor="pointer"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {notes}
                </Text>
              </Box>
            }
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: notes.replace(/\n/g, "<br />"),
              }}
            ></Box>
          </PopoverCustom>
        ),
    },

    {
      header: "",
      width: "100px",
      accessor: "id",
      callback: (id: string) => (
        <>
          <IconButton
            variant="ghost"
            aria-label="Download"
            size="sm"
            icon={<DownloadIcon />}
            onClick={() => handleDownload(id)}
          />
        </>
      ),
    },
  ];

  const handleSort = (props: { accessor: string; direction: 0 | 1 }) => {
    dispatch(
      update({
        sortColumn: { accessor: props.accessor, direction: props.direction },
      })
    );

    setCompletedDropoffs(
      sortDirection(completedDropoffs, props.accessor, props.direction)
    );
    setDropoffs(sortDirection(dropoffs, props.accessor, props.direction));
  };

  useEffect(() => {
    // fetch dropoffs
    init();
  }, []);

  return (
    <>
      <Flex flexDirection="row" justifyContent="flex-end" mb="16px">
        <ButtonCustom onClick={handleExport}>
          Export to csv
          <DownloadIcon ml="6px" />
        </ButtonCustom>
      </Flex>
      <Flex flexDirection="column">
        <Flex my="16px" flexDirection="row" justifyContent="flex-end">
          <ToggleInput
            style={{ justifyContent: "flex-end" }}
            leftLabel="Progress"
            rightLabel="Complete"
            onChange={(e) => {
              setToggled(e.target.checked);
            }}
          />
        </Flex>
        <TableContainer
          columns={columns}
          onSort={handleSort}
          sortColumn={sortColumn}
          hidden={!(toggled ? completedDropoffs : dropoffs)?.length}
        >
          <Tbody>
            {(toggled ? completedDropoffs : dropoffs).map((row, i) => (
              <Tr key={i}>
                {columns.map((column, j) => (
                  <Td
                    key={j}
                    width={column.width ?? "full"}
                    p={column.accessor === "notes" && 0}
                  >
                    {column.callback ? (
                      <>{column.callback(row[column.accessor], row)}</>
                    ) : (
                      <>{row[column.accessor]}</>
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </TableContainer>
      </Flex>
      <DropoffModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={init}
      />
    </>
  );
};
