import { Flex, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Modal } from "../app/Modal";
import { Loading } from "../shared/Loading";
import { FormInput } from "../form/controls/form.input";
import { errorToast, successToast } from "../../constants/toast.constants";
import { companyService } from "../../service/company/company.service";
import { CountryDropdownInput } from "../form/controls/country-dropdown.input";
import { ButtonCustom } from "../form/controls/form.button";

export const CompanyEditModal = ({ isOpen, onClose, onSubmit, company }) => {
  const toast = useToast();

  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [addressDirty, setAddressDirty] = useState(false);
 
  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleClose = () => {
    setFormState({});
    setAddressDirty(false);
    onClose();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const obj = { ...company, ...formState };
    const payload = {
      id: obj.id,
      name: obj.name,
      url: obj.url,
      country: obj.country,
    };

    await companyService
      .updateCompany(payload)
      .then(() => {
        toast(successToast("Company updated"));
        onSubmit(payload);
      })
      .catch(() => {
        toast(successToast("Error updating company"));
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal isOpen={isOpen} header="Edit company" onClose={handleClose}>
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <FormInput
            required
            title="Name"
            name="name"
            defaultValue={company.name}
            onChange={onInputChange}
          />
          <FormInput
            name="url"
            title="Company url"
            defaultValue={company.url}
            onChange={onInputChange}
          />
          <FormInput
            disabled
            name=""
            title="Address"
            defaultValue={company.addressName}
          />
          <CountryDropdownInput
            name="country"
            title="Country"
            defaultValue={company.country}
            onChange={onInputChange}
          />

          <Flex gap="12px" justifyContent="flex-end">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
            <ButtonCustom type="submit">Confirm</ButtonCustom>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
