export enum MediaContext {
  PROFILE_PHOTO = "profilePhoto",
  COMPANY_LOGO = "companyLogo",
  REPORT_ITAD = "reportItad",
  REPORT_DESTRUCTION = "reportDestruction",
}

export enum JobMediaContext {
  DESCTRUCTION_CERT = "destructionCertificate",
  SERVICE_REPORT = "serviceReport",
  OTHER = "other",
}
