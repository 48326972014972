import { Flex, Heading, Badge, useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { update as updateProfile } from "../../../../store/slice/Account/user-profile.slice";
import { AvatarCustom } from "../../../form/avatar.custom";
import { CardCustom } from "../../../shared/CardCustom";
import { useState } from "react";
import { UserService } from "../../../../service/user/user.service";
import {
  successToast,
  errorToast,
} from "../../../../constants/toast.constants";
import { ButtonCustom } from "../../../form/controls/form.button";
import { DownloadIcon, RepeatIcon } from "@chakra-ui/icons";
import { SummaryReportModal } from "../../inventory/summary-report.modal";
import { BlanccoSyncModal } from "../../inventory/blancco-sync.modal";

export const CustomProfileCard = ({}) => {
  const userService = new UserService();

  const profile = useSelector((state: RootState) => state.userProfile);
  const { email, isManager, photoUri } = profile;
  const dispatch = useDispatch();
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);

  const handlePhotoChange = async ({ formData, photoUri }) => {
    await userService
      .updateProfilePhoto(formData)
      .then((res) => {
        dispatch(updateProfile({ ...profile, photoUri }));
        toast(successToast("Profile photo updated"));
      })
      .catch((err) => {
        toast(errorToast("Error updating profile photo"));
      });
  };

  const handleProfileUpdate = async (data: any) => {
    dispatch(
      updateProfile({
        ...profile,
        email: data.email,
      })
    );
  };

  return (
    <>
      <CardCustom gap="6px" pt="70px">
        <Flex justifyContent="space-between" alignItems="center">
          <AvatarCustom
            src={photoUri}
            allowUpload
            widthxheight="100px"
            styles={{ position: "absolute", top: "70px" }}
            onUpload={handlePhotoChange}
          />
          <Heading size="lg">{email}</Heading>
        </Flex>
        <Flex flexDir="row" gap="6px" mb="16px">
          <Badge colorScheme="gray" w="fit-content">
            {isManager ? "Manager" : "Employee"}
          </Badge>
          <Badge colorScheme="green" w="fit-content">
            Active
          </Badge>
        </Flex>

        <ButtonCustom
          onClick={() => setIsOpen(true)}
          neutral
          style={{ width: "fit-content" }}
        >
          <DownloadIcon />
          Impact report
        </ButtonCustom>
      </CardCustom>
      <SummaryReportModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
