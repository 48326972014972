import { Box, Button, Flex, Text, Icon, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, destroyAllState } from "../../store";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../service/user/user.service";
import InboxIcon from "@mui/icons-material/Inbox";
import CarpenterIcon from "@mui/icons-material/Carpenter";
import ChecklistIcon from "@mui/icons-material/Checklist";
import BusinessIcon from "@mui/icons-material/Business";
import { AvatarCustom } from "../form/avatar.custom";
import ConstructionIcon from "@mui/icons-material/Construction";
import { magiclinkService } from "../../service/magiclink/magiclink.service";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const links = [
  // { route: "dashboard", name: "Dashboard", icon: DashboardIcon },
  { route: "dropoff", name: "Pickup Request", icon: InboxIcon },
  { route: "job", name: "Jobs", icon: CarpenterIcon },
  { route: "inventory", name: "Inventory", icon: ChecklistIcon },
  { route: "company", name: "Companies", icon: BusinessIcon },
  { route: "forge", name: "Forge", icon: ConstructionIcon },
];

export const Sidebar: React.FC<any> = (props: any) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { photoUri, email } = useSelector(
    (state: RootState) => state.userProfile
  );

  const logout = async () => {
    setLoading(true);
    await magiclinkService
      .logout()
      .then(() => {
        destroyAllState();
      })
      .finally(() => {
        navigate("/app/auth/login");
        setLoading(false);
      });
  };

  return (
    <Box
      maxW="240px"
      minH="100%"
      display={{ base: "none", lg: "block" }}
      position="fixed"
      zIndex={11}
    >
      <Flex
        minH="100vh"
        bg="brand.green"
        w="240px"
        maxW="240px"
        py="12px"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Flex flexDir="column" gap="6px" alignItems="center" mb="30px">
            <Image
              pos="relative"
              src="/logo-white.svg"
              alt="Zolo Logo"
              width="120px"
              height="30px"
              mb={6}
              cursor="pointer"
              onClick={() => navigate("/app/dashboard")}
            />

            <Box
              pos="absolute"
              right="20px"
              fontSize="10px"
              bg="brand.yellow"
              px="8px"
              borderRadius="4px"
              fontWeight="600"
            >
              ADMIN
            </Box>
          </Flex>

          {links.map((link, i) => {
            return (
              <LinkStyled
                active={window.location.pathname.startsWith(
                  `/app/${link.route}`
                )}
                key={i}
                onClick={() => navigate(`/app/${link.route}`)}
              >
                <Flex flexDirection="row" gap={3} alignItems={"center"}>
                  <Icon as={link.icon}></Icon>
                  <Text fontSize="14px">{link.name}</Text>
                </Flex>
              </LinkStyled>
            );
          })}
        </Box>
        <Box>
          <LinkStyled
            active={window.location.pathname.startsWith(`/app/account`)}
            onClick={() => navigate(`/app/account`)}
          >
            <Flex flexDirection="row" gap={3} alignItems={"center"}>
              <Flex flexDir="row" justifyContent="center">
                <Box onClick={() => navigate("/app/account")}>
                  <AvatarCustom src={photoUri} widthxheight="30px" />
                </Box>
              </Flex>
              <Text fontSize={"15px"}>{email}</Text>
            </Flex>
          </LinkStyled>

          <LinkStyled onClick={logout}>
            <Flex flexDirection="row" gap={5} alignItems={"center"}>
              <Icon as={LogoutIcon}></Icon>
              <Text fontSize={"15px"}>Logout</Text>
            </Flex>
          </LinkStyled>
        </Box>
      </Flex>
    </Box>
  );
};

const LinkStyled: React.FC<any> = (props: any) => {
  const { href, children, active, onClick } = props;

  return (
    <Button
      onClick={onClick}
      boxSize="initial"
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="center"
      bg="transparent"
      borderLeftWidth="4px"
      color={active ? "brand.yellow" : "white"}
      borderLeftColor={active ? "brand.yellow" : "transparent"}
      mb={{
        lg: "10px",
      }}
      mx={{
        xl: "auto",
      }}
      ps={{
        sm: "10px",
        xl: "16px",
      }}
      _hover={{
        color: "brand.yellow",
      }}
      py="12px"
      borderRadius="0"
      w="100%"
    >
      {children}
    </Button>
  );
};
