import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  ModalOverlay,
} from "@chakra-ui/react";

export const Modal = ({
  children,
  header,
  isOpen,
  onClose,
  width = undefined,
  height = undefined,
}) => (
  <ChakraModal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent maxWidth={width} height={height}>
      <ModalHeader>
        <Text fontSize="2xl" color="brand.green" fontWeight="700">
          {header}
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>{children}</ModalBody>
    </ModalContent>
  </ChakraModal>
);
