import { Flex, useToast, Text, Box } from "@chakra-ui/react";
import { ButtonCustom } from "../../form/controls/form.button";
import { Modal } from "../Modal";
import { TextAreaInput } from "../../form/controls/text-area.input";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { Loading } from "../../shared/Loading";
import { FormInput } from "../../form/controls/form.input";
import { CompanyService } from "../../../service/company/company.service";
import { DatepickerInput } from "../../form/controls/date-picker.input";
import { convertIsoTime } from "../../../util/date.util";
import EditIcon from "@mui/icons-material/Edit";
import { EmissionEditModal } from "./emission-edit.modal";

export const EmissionModal = ({
  isOpen,
  onClose,
  onSubmit,
  balance,
  companyId,
}) => {
  const companyService = new CompanyService();
  const toast = useToast();

  const [formState, setFormState] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  } as any);

  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    if (value === "") value = null;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await companyService
      .upsertEmissions({ ...formState, companyId })
      .then(() => {
        toast(successToast("Emission diverted added successfully."));
        handleClose();
        onSubmit(formState.carbonCredits);
      })
      .catch(() => {
        toast(errorToast("Error adding emission diverted."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setFormState({ fromDate: new Date(), toDate: new Date() });
    onClose();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Modal
      width="fit-content"
      isOpen={isOpen}
      header="Add emissions diverted"
      onClose={handleClose}
    >
      <Loading loading={loading} fill ignoreDelay />
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="12px">
          <Flex flexDir="row" justifyContent="space-between" gap="12px">
            <DatepickerInput
              required
              name="fromDate"
              title="From"
              initialDate={formState.fromDate}
              onChange={onInputChange}
            />
            <DatepickerInput
              required
              name="toDate"
              title="To"
              initialDate={formState.toDate}
              onChange={onInputChange}
            />
          </Flex>
          <FormInput
            required
            name="emission"
            title="Add emission diverted"
            type="number"
            onChange={onInputChange}
          />
          <TextAreaInput
            name="description"
            title="Description"
            onChange={onInputChange}
          />

          <Flex flexDir="row" justifyContent="flex-end">
            <ButtonCustom style={{ width: "fit-content" }} type="submit">
              Confirm
            </ButtonCustom>
          </Flex>

          <Flex flexDir="column" gap="12px">
            <History companyId={companyId} />
          </Flex>
          <Flex flexDir="row" justifyContent="flex-end" gap="12px" mt="12px">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

const History = ({ companyId }) => {
  const [emissions, setEmissions] = useState([]);
  const companyService = new CompanyService();

  const [editEmission, setEditEmission] = useState({
    emission: null,
    open: false,
  });

  const init = async () => {
    const emissions = await companyService.getEmissions(companyId);
    setEmissions(emissions);
  };

  const handleToggle = (isOpen) => {
    if (isOpen) {
      init();
    }
  };

  return (
    <Box>
      <Box
        fontWeight="bold"
        color="brand.green"
        my="16px"
        onClick={handleToggle}
        cursor="pointer"
      >
        View history
      </Box>
      <Box>
        {emissions.map((emission) => (
          <Flex flexDir="row" justifyContent="space-between">
            <Flex flexDir="row" gap="12px">
              <Text>
                {convertIsoTime(emission.fromDate)} -{" "}
                {convertIsoTime(emission.toDate)}
              </Text>
              <Text fontWeight="bold">{emission.emission}</Text>
            </Flex>

            <EditIcon
              width="18px"
              height="18px"
              cursor={"pointer"}
              style={{ color: "gray" }}
              onClick={() => setEditEmission({ emission, open: true })}
            />
          </Flex>
        ))}
      </Box>
      <EmissionEditModal
        emission={editEmission.emission}
        isOpen={editEmission.open}
        onClose={() => setEditEmission({ emission: null, open: false })}
        onSubmit={() => {
          setEditEmission({ emission: null, open: false });
          init();
        }}
      />
    </Box>
  );
};

export default History;
