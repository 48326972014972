import {
  Flex,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Text,
  Table as ChakraTable,
  useToast,
  Badge,
  Tooltip,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Button,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AlertDialog from "../../shared/AlertDialog";
import { InventoryService } from "../../../service/inventory/inventory.service";
import { errorToast, successToast } from "../../../constants/toast.constants";
import { EditInventoryModal } from "./edit-inventory.modal";
import { InventoryStatus } from "../../../models/enum/inventory/inventory-status.enum";
import { CheckboxInput } from "../../form/controls/checkbox.input";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { BulkEditInventoryModal } from "./bulk-edit-inventory.modal";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { InventoryFilterModal } from "./inventory-filter.modal";
import { InventoryBulkFilter } from "../../../models/filter/inventory-bulk.filter";
import { InventoryModel } from "../../../models/inventory.model";
import { hasNonNullProperty } from "../../../util/object.util";
import { QrCustom } from "../../shared/QrCustom";
import { StatusPickerInput } from "../../form/controls/status-picker.input";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { BulkQrMoldal } from "./bulk-qr.modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PopoverCustom from "../../shared/PopoverCustom";
import { TableContainer } from "../../shared/table/TableContainer";
import { useAppSelector } from "../../../util/hooks.util";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  updateJobSort,
  updateSort,
} from "../../../store/slice/Account/inventory.slice";
import { FormBuilderService } from "../../../service/forge/form-builder.service";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { PreviewDescrutionReportModal } from "./preview-destruction-report.modal";
import { FormInput } from "../../form/controls/form.input";
import { CustomFieldType } from "../../../models/form-builder/custom-field-type.enum";
import { CategoryPickerInput } from "../../form/controls/category-picker";
import { InventoryCategory } from "../../../models/enum/inventory/inventory-category.enum";

interface Column {
  header: string | JSX.Element;
  width?: string;
  accessor: string;
  disableSort?: boolean;
  isCustom?: boolean;
  callback?: (arg0: any, arg1?: any) => JSX.Element;
}

export const InventoryTable: React.FC<Props> = (props: Props) => {
  const {
    inventory: initialInventory,
    footer,
    isJobDetail,
    onDelete,
    onBulkDelete,
    onEdit,
    onCheckChange,
    onFilterChange,
    onSort,
  } = props;

  const inventoryService = new InventoryService();
  const formbuilderService = new FormBuilderService();

  const toast = useToast();
  const dispatch = useDispatch();

  const { isManager } = useSelector((state: any) => state.userProfile);
  const { formId } = useSelector((state: any) => state.inventory);
  const { filter, jobFilter, sortColumn, jobSortColumn } = useAppSelector(
    (state) => state.inventory
  );

  const [destructionReport, setDestructionReport] = useState({
    isOpen: false,
    blanccoReportId: null,
  });

  const [formJson, setFormJson] = useState([] as any);
  const [filterModal, setFilterModal] = useState(false);
  const [bulkEditInventoryModal, setBulkEditInventoryModal] = useState(false);
  const [bulkQrModal, setBulkQrModal] = useState(false);
  const [bulkDeleteInventoryModal, setBulkDeleteInventoryModal] =
    useState(false);
  const [editInventoryModal, setEditInventoryModal] = useState({
    id: "",
    open: false,
  });
  const [deleteInventoryModal, setDeleteInventoryModal] = useState({
    id: "",
    open: false,
  });

  const [focusedInventory, setFocusedInventory] = useState([
    {},
  ] as InventoryModel[]);

  // INLINE EDIT
  const [formState, setFormState] = useState({} as any);
  const [isEditing, setIsEditing] = useState(null);
  const [inventory, setInventory] = useState(
    initialInventory as InventoryModel[]
  );

  const onInputChange = useCallback(
    (event: any) => {
      let { name, value } = event.target;

      if (value === "") value = null;

      setFormState((prev) => ({
        [name]: value,
      }));
    },
    [setFormState]
  );

  const handleSubmit = async (row, name) => {
    if (row[name] === formState[name]) {
      return;
    }
    const obj = formState;
    setInventory((prev) => {
      return prev.map((x) => (x.id === row.id ? { ...x, ...obj } : x)) as any;
    });

    await inventoryService
      .update({ id: row.id, ...obj })
      .then(() => {
        toast(successToast("Inventory updated."));
      })
      .catch(() => {
        toast(errorToast("Error updating inventory."));
      });
  };

  const handleCategoryUpdate = async (id: string, category: string) => {
    await inventoryService
      .update({ id, category })
      .then(() => {
        toast(successToast("Inventory updated."));
      })
      .catch(() => {
        toast(errorToast("Error updating inventory."));
      });
  };

  // checkbox
  const [checkedItems, setCheckedItems] = useState([{}] as {
    id: string;
    isChecked: boolean;
  }[]);

  const allChecked = checkedItems.map((x) => x.isChecked).every((x) => !!x);
  const isIndeterminate =
    checkedItems.map((x) => x.isChecked).some(Boolean) && !allChecked;

  const handleStatusUpdate = async (id: string, status: string) => {
    await inventoryService.updateStatus({ id, status });
  };

  const handleDelete = async () => {
    await inventoryService.delete(deleteInventoryModal.id).then(async () => {
      onDelete(deleteInventoryModal.id);
      setDeleteInventoryModal({ id: "", open: false });
      toast(successToast("Inventory deleted"));
    });
  };

  const handleBulkDelete = async () => {
    await inventoryService
      .bulkDelete(checkedItems.map((x) => x.id))
      .then(async () => {
        onEdit();
        setBulkDeleteInventoryModal(false);
        toast(successToast("Inventory item(s) deleted"));
        if (onBulkDelete) {
          onBulkDelete(checkedItems.map((x) => x.id));
        }
        setCheckedItems(JSON.parse(JSON.stringify([{}])) as any);
      });
  };

  const handleBulkFilter = async (filter: InventoryBulkFilter) => {
    const hasVal = hasNonNullProperty(filter);
    if (hasVal) {
      onFilterChange(filter);
    }
  };

  useEffect(() => {
    if (onCheckChange) {
      onCheckChange(checkedItems);

      const focusedInventory = inventory.filter((x) =>
        checkedItems
          .filter((y) => x.id === y.id && y.isChecked)
          .map((z) => z.id)
          .includes(x.id)
      );

      setFocusedInventory(focusedInventory);
    }
  }, [checkedItems]);

  useEffect(() => {
    const keyPressHandler = (event) => {
      if (event.key === "e" && event.metaKey) {
        setBulkEditInventoryModal(true);
      }
    };

    window.addEventListener("keydown", keyPressHandler);

    return () => {
      window.removeEventListener("keydown", keyPressHandler);
    };
  }, []);

  const init = async () => {
    const form = await formbuilderService.get(formId);
    setFormJson(form.formJson);
  };

  useEffect(() => {
    init();
    setInventory(initialInventory);
  }, [formId, initialInventory]);

  const handleSort = (props: { accessor: string; direction: 0 | 1 }) => {
    dispatch(
      isJobDetail
        ? updateJobSort({
            jobSortColumn: props,
          })
        : updateSort({
            sortColumn: props,
          })
    );

    if (onSort) onSort(props);
  };

  const handleCheck = ({ id, isChecked }) => {
    setCheckedItems((prevItems) => {
      if (isChecked) {
        return [...prevItems, { id, isChecked }];
      } else {
        return prevItems.filter((item) => item.id !== id);
      }
    });
  };

  const InlineEditField = ({ accessor, value, row }) => {
    return (
      <Box h="100%" key={`${row.id} ${accessor}`}>
        {isEditing === `${row.id} ${accessor}` ? (
          <FormInput
            name={accessor}
            value={formState[accessor] || undefined}
            onChange={onInputChange}
            type={
              accessor === "rebate" || accessor === "salePrice"
                ? "number"
                : undefined
            }
            onBlur={(event) => {
              event.preventDefault();
              event.stopPropagation();

              if (
                event.key !== "Enter" ||
                event.key !== "Tab" ||
                event.key !== "Escape"
              ) {
                handleSubmit(row, accessor);
              }
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>): void => {
              if (event.key === "Escape") {
                event.preventDefault();
                event.stopPropagation();
                setIsEditing(null);
                return;
              } else if (event.key === "Tab") {
                handleSubmit(row, accessor);
              } else if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                setIsEditing(null);

                handleSubmit(row, accessor);
              }
            }}
            autoFocus
          />
        ) : (
          <Box
            tabIndex={0}
            display="flex"
            alignItems="center"
            h="100%"
            onFocus={() => {
              setIsEditing(`${row.id} ${accessor}`);
              setFormState({ [accessor]: value });
            }}
            _hover={{
              bgColor: "gray.200",
            }}
            cursor="pointer"
          >
            {value}
          </Box>
        )}
      </Box>
    );
  };

  const columns: Column[] = [
    {
      header: (
        <CheckboxInput
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={(e) =>
            setCheckedItems(
              inventory.map((x) => ({ id: x.id, isChecked: e.isChecked }))
            )
          }
        />
      ),
      disableSort: true,
      width: "10px",
      accessor: "id",
      callback: (id: string) => (
        <CheckboxInput
          id={id}
          isChecked={!!checkedItems.find((x) => x.id === id)?.isChecked}
          onChange={handleCheck}
        />
      ),
    },
    {
      header: "Job",
      accessor: "jobId",
      width: "60px",
      callback: (jobId) => {
        return isJobDetail ? (
          <></>
        ) : (
          <Box color="brand.green" cursor={"pointer"}>
            <LaunchOutlinedIcon
              onClick={() =>
                window.open(
                  `/app/job/${jobId}`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            />
          </Box>
        );
      },
    },
    {
      header: "QR",
      width: "50px",
      accessor: "qrNo",
      callback: (qrNo: number, row: InventoryModel) => (
        <Popover>
          <PopoverTrigger>
            <Button size="xs" variant="ghost" isDisabled={row.deleted}>
              {qrNo}
            </Button>
          </PopoverTrigger>
          <PopoverContent width="fit-content" pt={4}>
            <PopoverArrow />
            <PopoverBody>
              <QrCustom id={row.id} number={qrNo} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      header: "Category",
      width: "100px",
      accessor: "category",
      callback: (category: string, row: InventoryModel) =>
        !row.deleted ? (
          <CategoryPickerInput
            id={row.id}
            category={category}
            options={InventoryCategory}
            onClick={handleCategoryUpdate}
          />
        ) : (
          <Box>{category}</Box>
        ),
    },
    {
      header: "Make",
      width: "75px",
      accessor: "make",
      callback: (make: string, row: InventoryModel) => (
        <InlineEditField accessor="make" value={make} row={row} />
      ),
    },
    {
      header: "Model",
      width: "100px",
      accessor: "model",
      callback: (model: string, row: InventoryModel) => (
        <InlineEditField accessor="model" value={model} row={row} />
      ),
    },
    {
      header: "Serial",
      width: "120px",
      accessor: "serialNumber",
      callback: (serialNumber: string, row: InventoryModel) => (
        <InlineEditField
          accessor="serialNumber"
          value={serialNumber}
          row={row}
        />
      ),
    },
    {
      header: "Processor",
      width: "120px",
      accessor: "processor",
      callback: (processor: string, row: InventoryModel) => (
        <InlineEditField accessor="processor" value={processor} row={row} />
      ),
    },
    {
      header: "RAM",
      width: "75px",
      accessor: "ram",
      callback: (ram: string, row: InventoryModel) => (
        <InlineEditField accessor="ram" value={ram} row={row} />
      ),
    },
    {
      header: "Bin",
      width: "100px",
      accessor: "zolo_custom_bin_no.",
      disableSort: true,
      isCustom: true,
    },
    {
      header: "Sale ($)",
      width: "120px",
      accessor: "salePrice",
      callback: (salePrice: string, row: InventoryModel) => (
        <InlineEditField accessor="salePrice" value={salePrice} row={row} />
      ),
    },
    {
      header: "Rebate ($)",
      width: "100px",
      accessor: "rebate",
      callback: (rebate: string, row: InventoryModel) => (
        <InlineEditField accessor="rebate" value={rebate} row={row} />
      ),
    },
    {
      header: "Status",
      width: "75px",
      accessor: "status",
      callback: (status: string, row: InventoryModel) =>
        !row.deleted ? (
          <StatusPickerInput
            id={row.id}
            status={status}
            options={InventoryStatus}
            onClick={handleStatusUpdate}
          />
        ) : (
          <Badge colorScheme="gray" color="gray.300">
            {status}
          </Badge>
        ),
    },
    {
      header: "Notes",
      accessor: "notes",
      width: "200px",
      callback: (notes) =>
        notes?.length && (
          <PopoverCustom
            placement="left"
            width="150px"
            trigger={
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                px={6}
                _hover={{
                  bgColor: "gray.200",
                }}
                cursor="pointer"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {notes}
                </Text>
              </Box>
            }
          >
            <Text whiteSpace="normal">{notes}</Text>
          </PopoverCustom>
        ),
    },
    {
      disableSort: true,
      header: "",
      accessor: "id",
      width: "60px",
      callback: (id, row) => (
        <Flex flexDirection="row" gap="16px" justifyContent="flex-end">
          {row.synced && !!row.blanccoReportId && (
            <Tooltip label="Destruction Report" aria-label="destruction">
              <DownloadIcon
                width="18px"
                height="18px"
                cursor="pointer"
                style={{
                  color:
                    row.blanccoReportId === destructionReport.blanccoReportId
                      ? "green"
                      : "gray",
                  marginTop: "4px",
                  marginLeft: "4px",
                }}
                onClick={() =>
                  setDestructionReport({
                    blanccoReportId: row.blanccoReportId,
                    isOpen: true,
                  })
                }
              />
            </Tooltip>
          )}
          <EditIcon
            width="18px"
            height="18px"
            cursor={"pointer"}
            style={{ color: "gray" }}
            onClick={() => setEditInventoryModal({ id, open: true })}
          />
          <DeleteIcon
            width="18px"
            height="18px"
            cursor={"pointer"}
            style={{
              color: "gray",
              display: row.deleted ? "none" : "",
            }}
            onClick={() =>
              setDeleteInventoryModal({
                id,
                open: true,
              })
            }
          />
        </Flex>
      ),
    },
  ];

  const tableColumns = useMemo(() => {
    let copy = columns;

    if (!isManager) {
      copy = copy.filter(
        (x) => x.accessor !== "salePrice" && x.accessor !== "quotePrice"
      );
    }

    return isJobDetail ? copy?.filter((x) => x.accessor !== "jobId") : copy;
  }, [columns, isManager, isJobDetail, isEditing]);

  return (
    <Flex flexDirection="column">
      <Flex
        px="16px"
        flexDir="row"
        color="brand.green"
        justifyContent="flex-start"
        h="35px"
        gap="16px"
      >
        <Flex flexDir="column" position="relative">
          {Object.keys(isJobDetail ? jobFilter : filter).length ? (
            <FilterAltIcon
              onClick={() => setFilterModal(true)}
              style={{
                color: "gray",
                cursor: "pointer",
                fontSize: "22px",
              }}
            />
          ) : (
            <FilterAltOffIcon
              onClick={() => setFilterModal(true)}
              style={{
                color: "gray",
                cursor: "pointer",
                fontSize: "22px",
              }}
            />
          )}
        </Flex>

        <Tooltip label="Bulk edit (CMD + e)" aria-label="edit">
          <EditNoteIcon
            onClick={() => setBulkEditInventoryModal(true)}
            style={{
              color: "gray",
              cursor: "pointer",
              fontSize: "22px",
              display: allChecked || isIndeterminate ? "block" : "none",
            }}
          />
        </Tooltip>
        <Tooltip label="Bulk delete" aria-label="edit">
          <DeleteForeverIcon
            onClick={() => setBulkDeleteInventoryModal(true)}
            style={{
              color: "gray",
              cursor: "pointer",
              fontSize: "22px",
              display: allChecked || isIndeterminate ? "block" : "none",
            }}
          />
        </Tooltip>
        <Tooltip label="Bulk QR download" aria-label="edit">
          <QrCodeScannerIcon
            onClick={() => setBulkQrModal(true)}
            style={{
              color: "gray",
              cursor: "pointer",
              fontSize: "22px",
              display: allChecked || isIndeterminate ? "block" : "none",
            }}
          />
        </Tooltip>
      </Flex>

      <TableContainer
        key={inventory.length}
        columns={tableColumns}
        sortColumn={isJobDetail ? jobSortColumn : sortColumn}
        onSort={handleSort}
        h="74vh"
      >
        <Tbody>
          {inventory.map((row, i) => (
            <Tr
              key={row.id}
              style={
                checkedItems
                  .filter((x) => x.isChecked)
                  .map((x) => x.id)
                  .includes(row.id)
                  ? { backgroundColor: "#ECECEC" }
                  : {}
              }
              color={row.deleted ? "gray.300" : undefined}
              height="100%"
            >
              {tableColumns.map((column, j) => {
                if (column.isCustom) {
                  return (
                    <Td key={j} width={column.width}>
                      {row.customFieldsJson[column.accessor]}
                    </Td>
                  );
                }

                return (
                  <Td key={j} width={column.width}>
                    {column.callback
                      ? column.callback(row[column.accessor], row)
                      : row[column.accessor]}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </TableContainer>
      {footer}

      <AlertDialog
        isOpen={bulkDeleteInventoryModal}
        onClose={() => setBulkDeleteInventoryModal(false)}
        title="Confirmation required"
        colorScheme="red"
        confirmText="Delete"
        onConfirm={handleBulkDelete}
      >
        Delete {`${checkedItems?.filter((x) => x.isChecked)?.length ?? 0}`}{" "}
        items? You can't undo this.
      </AlertDialog>

      <BulkEditInventoryModal
        isOpen={bulkEditInventoryModal}
        onClose={() => setBulkEditInventoryModal(false)}
        inventoryIds={checkedItems.filter((x) => x.isChecked).map((x) => x.id)}
        onSubmit={onEdit}
        formJson={formJson}
      />

      <InventoryFilterModal
        isOpen={filterModal}
        isJobDetail={isJobDetail}
        onClose={() => setFilterModal(false)}
        onClear={() => onFilterChange({})}
        onSubmit={(data) => {
          handleBulkFilter(data);
          setFilterModal(false);
        }}
      />

      <BulkQrMoldal
        inventory={focusedInventory.map((x) => ({ id: x.id, qrNo: x.qrNo }))}
        isOpen={bulkQrModal}
        onClose={() => setBulkQrModal(false)}
        onSubmit={() => {}}
      />

      <EditInventoryModal
        inventoryId={editInventoryModal.id}
        isOpen={editInventoryModal.open}
        onClose={() => setEditInventoryModal({ id: "", open: false })}
        onSubmit={onEdit}
        formJson={formJson}
      />

      <PreviewDescrutionReportModal
        blanccoReportId={destructionReport.blanccoReportId}
        isOpen={destructionReport.isOpen}
        onClose={() =>
          setDestructionReport({ blanccoReportId: null, isOpen: false })
        }
        onSubmit={() => {}}
      />

      <AlertDialog
        isOpen={deleteInventoryModal.open}
        onClose={() => setDeleteInventoryModal({ id: "", open: false })}
        title="Confirmation required"
        colorScheme="red"
        confirmText="Delete"
        onConfirm={() => handleDelete()}
      >
        Are you sure? You can't undo this action.
      </AlertDialog>
    </Flex>
  );
};

interface Props {
  inventory: InventoryModel[];
  isJobDetail?: boolean;
  onDelete?: (id: string) => void;
  onBulkDelete?: (id: string[]) => void;
  onEdit?: () => void;
  onCheckChange?: (data: { id: string; isChecked: boolean }[]) => void;
  onFilterChange?: (data: InventoryBulkFilter) => void;
  onSort?: (data: { accessor: string; direction: 0 | 1 }) => void;
  disableEdit?: boolean;
  footer?: any;
}
